const config = {
  headers: {
    Authorization: `Bearer "${localStorage.getItem("token")}"`,
  },
};
const config2 = {
  headers: {
    Authorization: `Bearer "${localStorage.getItem("token")}"`,
    "Content-Type": "application/json",
  },
};
const statesData = [
  {
    state: "Andhra Pradesh",
    erc: "APERC",
    dist: "APGENCO ",
  },
  {
    state: "Arunachal Pradesh",
    erc: "APSERC",
    dist: "APPCPL",
  },
  {
    state: " Assam",
    erc: "AERC",
    dist: "APGCL",
  },
  {
    state: " Bihar",
    erc: "BERC",
    dist: "BSPHCL",
  },
  {
    state: " Chhattisgarh",
    erc: "CSERC",
    dist: "CSPDCL",
  },
  {
    state: "Delhi",
    erc: "DERC",
    dist: "DPCL",
  },
  {
    state: "Tamil Nadu",
    erc: "TNERC",
    dist: "TANGEDCO",
  },
];

function year1() {
  const start = 1997;
  const end = 2050;
  const yearOption = [];

  for (let i = start; i < end; i++) {
    yearOption.push({
      label: i,
      value: i,
    });
  }

  return yearOption;
}

function states1() {
  return statesData;
}
function stateErc(state) {
  console.log("glabalState", state);
  let data = statesData.filter((s) => s.state === state);
  console.log("global Data", data);
  return data[0].erc;
}
function stateDist(state) {
  console.log("glabalState", state);
  let data = statesData.filter((s) => s.state === state);
  console.log("global Data", data);
  return data[0].dist;
}
module.exports = {
  token: localStorage.getItem("token"),
  //base_url: "https://cag.prodmirror.com/",
//  base_url: "http://172.105.54.236:8000/",
 base_url: "https://ebcalc.cag.org.in/",

  headers: { Accept: "*/*", "Content-type": "application/json; charset=UTF-8" },
  user_auth: "",
  config,
  config2,
  year1,
  states1,
  stateErc,
  stateDist,
};
