import React, { useEffect, useState } from "react";
//import LoginHeader from "./LoginHeader";
import "../assets/css/login.css";
import { Link, Redirect } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { withStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import Checkbox from "@material-ui/core/Checkbox";
import { Button } from "@material-ui/core";
import Global from "../global";
import axios from "axios";
function Login(props) {
  // console.log("Login props", props);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [blankEmail, setBlankEmail] = useState(true);
  const [securetext, setSecuretext] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [blankPassword, setBlankPassword] = useState(true);
  // const [checked, setChecked] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [credentials, setCredentials] = useState(false);
  useEffect(() => {
    if (props.location.success) {
      setSuccess(props.location.success.isComplete);
    }
  }, []);
  const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  const onLogin = async () => {
    if (reg.test(email) === false) {
      setValidEmail(false);
    } else {
      setValidEmail(true);
    }
    if (email.length === 0) {
      setBlankEmail(false);
      setValidEmail(true);
    } else {
      setBlankEmail(true);
    }

    if (password.length === 0) {
      setBlankPassword(false);
    } else {
      setBlankPassword(true);
    }

    if (reg.test(email) === true && email.length > 0 && password.length > 0) {
      try {
        const response = await axios.post(
          Global.base_url + "login",
          {
            email: email,
            password: password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        // console.log(response);
        if (response.status === 200) {
          let token = response.headers.auth_token;
          // props.login();
          localStorage.setItem("cag_key", token);
          setLoading(false);
          return props.history.replace("/home");
          // const { from } = props.location.state || {
          //   from: {
          //     pathname:
          //       response.data.user === 0 ? "/userDashboard" : "/admindashboard",
          //   },
          // };
          // console.log("from", from);
          // from.pathname =
          //   from.pathname === "/userDashboard" ||
          //   from.pathname === "/admindashboard"
          //     ? response.data.user === 0
          //       ? "/userDashboard"
          //       : "/admindashboard"
          //     : props.location.state.from.pathname;
          // return props.history.replace(from);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.status !== undefined
        ) {
          return setLoading(false), setInvalid(true);
        }
        console.log("error", error);
        setLoading(false);
      }
    }
  };
  // const GreenCheckbox = withStyles({
  //   root: {
  //     color: blue[400],
  //     "&$checked": {
  //       color: blue[600],
  //     },
  //   },
  //   checked: {},
  // })((props) => <Checkbox color="default" {...props} />);

  return (
    <main className="container ">
      <section className="login__container ">
        <div className="sub-container">
          {/* <p>
            Don’t have an account? <Link className="signup">Sign Up</Link>
          </p> */}
          <h3 className="login__header">Admin</h3>
          {/* <form action="#" className="form__"> */}
          <div className="login__main ">
            {/* <div
              style={{
                color: "#03A33B",
                fontFamily: "mediumFont",
                display: success ? "block" : "none",
              }}
            >
              Signup Completed Successfully
            </div> */}
            <div
              style={{
                color: "#03A33B",
                fontFamily: "mediumFont",
                display: invalid ? "block" : "none",
              }}
            >
              <p style={{ color: "red", fontFamily: "mediumFont" }}>
                Invalid email and password
              </p>
              {/* {props.location.state.isComplete !== undefined
              ? "complted"
              : "faliled"} */}
            </div>

            <div className="inputBox">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                name="email"
                id="email"
                placeholder="name@email.com"
                value={email}
                style={{
                  border:
                    blankEmail && validEmail
                      ? "1px solid #A9C1DB"
                      : "1px solid red",
                  width: "100%",
                }}
                onChange={(name) => setEmail(name.target.value)}
              />
              {blankEmail ? null : <p className="err">Email can't be blank</p>}
              {validEmail ? null : <p className="err">Email must be valid</p>}
            </div>
            <div className="inputBox" style={{ position: "relative" }}>
              <label htmlFor="password">Password</label>
              <input
                type={securetext ? "password" : "text"}
                name="password"
                id="password"
                placeholder="password"
                value={password}
                style={{
                  border: blankPassword ? "1px solid #A9C1DB" : "1px solid red",
                  width: "100%",
                }}
                onChange={(name) => setPassword(name.target.value)}
              />

              <div
                className="password__icon"
                onClick={() =>
                  securetext ? setSecuretext(false) : setSecuretext(true)
                }
              >
                {securetext ? <AiFillEye /> : <AiFillEyeInvisible />}
              </div>
              {blankPassword ? null : (
                <p className="err">Password can't be blank</p>
              )}
              {/* <div className="forget__password ">
                <Link to="#">Forget password?</Link>
              </div> */}
            </div>
          </div>
          {/* <div className="inputCheckBox">
          <GreenCheckbox
            checked={checked}
            onChange={() => setChecked(!checked)}
            name="checkbox"
          />
          <p className="terms">
            I agree to passport2immunity{" "}
            <Link to="/" className="forget__link">
              Terms
            </Link>{" "}
            and{" "}
            <Link to="/" className="forget__link">
              Policy
            </Link>
          </p>
        </div> */}
          <Button
            variant="contained"
            id="signin"
            onClick={() => {
              return setLoading(true), onLogin();
            }}
            style={{
              backgroundColor: "#e37d20",
              padding: "10px 60px",
              marginTop: "80px",
            }}
            // disabled={checked ? false : true}
          >
            {loading && validEmail && blankPassword ? (
              <div class="spinner-border text-light" role="status">
                <span class="sr-only d-none">Loading...</span>
              </div>
            ) : (
              "sign in"
            )}
          </Button>
          {/* </form> */}
        </div>
      </section>
    </main>
  );
}

export default Login;
