import React, { useState, useEffect } from "react";
import BarChart from "./TransactionBar";
import LineChart from "./LineChart";
import DoughnutChart from "./Doughnut";

import Select from "react-select";
import "../assets/css/transaction.css";
import Global from "../global";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Loader from "react-loader-spinner";
import TransactionMain from "./TransactionMain";
import { MdKeyboardBackspace } from "react-icons/md";
import { IconContext } from "react-icons";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import { Button, Tooltip } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import * as yup from "yup";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "#7f63f4",
  },
  tooltip: {
    backgroundColor: " #ffe8d2",
    padding: "10px",
    color: "#7f63f4",
    fontSize: "14px",
    fontFamily: "mediumFont",
    border: "1px solid #7f63f4",
  },
}));
const dropDownCustom = {
  control: (base, state) => ({
    ...base,
    background: "#fff",
    padding: "7px",
    border: "1px solid rgb(184, 184, 184)",
    outline: "none",
    "&:active": {
      border: state.isFocused ? "1px solid #B3CDFF80" : "1px solid #B3CDFF80",
      outline: "none",
      boxShadow: "none",
    },
    "&:hover": {
      border: state.isFocused ? "1px solid #B3CDFF80" : "1px solid #B3CDFF80",
      outline: "none",
      boxShadow: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    border: "none",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? "#eee" : "#fff",
    padding: "8px",
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    zIndex: 9999,
    color: state.isFocused ? "#000" : "#000",
    "&:hover": {
      backgroundColor: state.isFocused ? "#eee" : "#fff",
    },
  }),
  menuList: (base) => ({
    ...base,
    border: "1px solid #eee",
  }),
};

const backgroundColor = ["#cd84f1", "#82ccdd", "#ff5e57", "#0be881"];

const Transaction = () => {
  const tooltipclass = useStylesBootstrap();
  const [state, setState] = useState({
    label: "Tamil Nadu",
    value: "Tamil Nadu",
  });
  const config2 = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const [stateOptions, setStateOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [categoryOptions, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [year, setYear] = useState("");
  const [next, setNext] = useState(true);
  const [tableData, setTable] = useState("");
  const [active, setActive] = useState(0);
  let currentYear = new Date().getFullYear();
  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const req1 = await axios.get(
          Global.base_url + "transaction/getSlabState",
          {
            // params: {
            //   state: state.value,
            //   year: year.value,
            // },
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const req2 = await axios.get(
          Global.base_url + "transaction/getSlabYear",
          {
            params: {
              state: state.value,
            },
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const response = await Promise.all([req1, req2]);
        //  console.log(response);

        if (response[0].status === 200 && response[1].status === 200) {
          setYear(response[1].data.data);
          // setYearOptions(response[1].data.data);
          setStateOptions(response[0].data.data);

          return setLoading(false);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.status !== undefined
        ) {
          // console.log("error");
          // return history.replace("/");
        }
      }
    }
    getData();
  }, [state, year]);
  const required = <span style={{ color: "red" }}>*</span>;
  // console.log({ year });

  async function getCategory(consumer) {
    try {
      const response = await axios.get(
        Global.base_url + "transaction/getCategory",
        {
          params: {
            state: state.value,
            consumer_type: consumer,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      //  console.log(response);

      if (response.status === 200) {
        return setCategory(response.data.data);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.status !== undefined
      ) {
        console.log("error");
        // return history.replace("/");
      }
    }
  }
  // console.log({ tableData });
  function getPercentage(amount, data) {
    let g = (amount / 100) * data.generation;
    let p = (amount / 100) * data.powerPurchase;
    let t = (amount / 100) * data.transmission;
    let d = (amount / 100) * data.distribution;
    let l = (amount / 100) * data.lineloss;

    let sum = g + p + t + d + l;
    //  console.log({ g, p, t, d, l, sum });
    let total = sum;
    let values = [
      Math.round(g),
      Math.round(p),
      Math.round(t),
      Math.round(d),
      Math.round(l),
      Math.round(total),
    ];

    return values;
  }
  function getEPercentage(amount, data) {
    //  console.log({ amount, data });
    let g = (amount / 100) * data.generation;
    let p = (amount / 100) * data.powerPurchase;
    let t = (amount / 100) * data.transmission;
    let d = (amount / 100) * data.distribution;
    let l = (amount / 100) * data.lineloss;
    let e = (d * data.employee_cost) / 100;
    let sum = g + p + t + d + l;
    let total = sum;

    // console.log({ g, p, t, d, l, sum });
    let values = [
      g.toFixed(2),
      p.toFixed(2),
      t.toFixed(2),
      d.toFixed(2),
      l.toFixed(2),
      total.toFixed(2),
      e.toFixed(4),
    ];
    return values;
  }
  return (
    <>
      <div className="w-100 p-3" style={{ backgroundColor: "#f3f3f3" }}>
        {loading ? (
          <div className="loader-position">
            <Loader
              type="TailSpin"
              color="#E37D20"
              height={80}
              width={80}
              //3 secs
            />
          </div>
        ) : (
          <>
            <div className="row  align-items-center">
              <div className="col-7 ">
                {/* <h2 className="header-text">{`${
                  state !== null ? state.value : ""
                } Slab Rates- ${year !== null ? year.value : ""}`}</h2> */}
                <h2 className="header-text">
                  What makes your electricity bill amount?
                </h2>
              </div>
              <div className="col-5 select-container">
                <div className="content-width-select">
                  <Select
                    options={stateOptions}
                    defaultValue={"Tamil Nadu"}
                    styles={dropDownCustom}
                    onChange={(value) => {
                      // setYear(null);
                      setState(value);
                    }}
                    value={state}
                    className="select-box"
                    placeholder="State"
                    isDisabled={true}
                  />
                </div>
                {/* <div className="content-width-select">
                  <Select
                    options={yearOptions}
                    styles={dropDownCustom}
                    onChange={(value) => setYear(value)}
                    value={year}
                    className="select-box"
                    placeholder="Year"
                  />
                </div> */}
              </div>
            </div>

            <hr className="line mb-5"></hr>
            <div className="row justify-content-between">
              <div className="col total-chart formcalc">
                <Formik
                  initialValues={{
                    consumer_type: null,
                    tariff_category: null,
                    tariff_label: null,
                    load: "",
                    units: "",
                  }}
                  validateOnChange={false}
                  validateOnBlur={false}
                  validationSchema={yup.object({
                    consumer_type: yup
                      .object()
                      .required("Please select consumer type")
                      .nullable(),
                    tariff_category: yup
                      .object()
                      .required("Please select category")
                      .nullable(),
                    load: yup.number().required("Please select load"),
                    units: yup.number().required("Please select units"),
                  })}
                  onSubmit={async (values, formik) => {
                    //    console.log({ values });
                    let data = {
                      state: state.value,
                      year: year,
                      consumer_type: values.consumer_type.value,
                      category: values.tariff_category.value,
                      load: values.load,
                      units: values.units,
                    };
                    // console.log({ data });
                    let api_url = Global.base_url + "transaction/rates_ht";
                    let api_url2 = Global.base_url + "transaction/rates_ht2";
                    if (values.consumer_type.value === "LT") {
                      api_url = Global.base_url + "transaction/rates_lt";
                      api_url2 = Global.base_url + "transaction/rates_lt2";
                    }

                    try {
                      const req1 = await axios.post(api_url, data, config2);
                      const req2 = await axios.get(
                        Global.base_url + "dashboard/getOtherAcos",
                        {
                          params: {
                            state: state.value,
                            year: year,
                          },
                          headers: {
                            "Content-Type": "application/json",
                          },
                        }
                      );
                      const req3 = await axios.post(api_url2, data, config2);

                      const result = await Promise.all([req1, req2, req3]);
                      // console.log("api", result);
                      if (
                        result[0].status === 200 &&
                        result[1].status === 200 &&
                        result[2].status === 200
                      ) {
                        let data = {
                          amount: result[0].data[0],
                          breakup: result[1].data.filter(
                            (d) => d.state === state.value
                          )[0],
                          comparison: result[2].data,
                          stateCmpr: result[1].data,
                        };
                        // console.log(data);
                        return setTable(data);
                      }
                    } catch (error) {
                      //  console.log({ error });
                      if (error && error.response) {
                        if (error.response.status === 403) {
                          return formik.setFieldError("state", "failed");
                        }
                        if (error.response.status === 401) {
                          return formik.setFieldError(
                            "consumer_type",
                            "Calculation failed"
                          );
                        }
                      }
                    }
                  }}
                >
                  {(formik) => {
                    //   console.log(formik.values, formik.errors);
                    return (
                      <>
                        <div className="row">
                          <div className="col">
                            <div
                              className="form-group mb-3"
                              style={{ position: "relative" }}
                              id="Consumer"
                            >
                              <Tooltip
                                title="Please refer to your EB meter card for this information. "
                                placement="right"
                                classes={tooltipclass}
                                enterTouchDelay={500}
                                arrow
                              >
                                <label
                                  htmlFor="consumer_type"
                                  className="create-label"
                                >
                                  Consumer Type {required}
                                </label>
                              </Tooltip>
                              <Select
                                value={formik.values.consumer_type}
                                onChange={(p) => {
                                  formik.setFieldValue("consumer_type", p);
                                  formik.setFieldValue("tariff_category", null);
                                  formik.setFieldValue("tariff_label", null);
                                  getCategory(p.value);
                                }}
                                options={[
                                  {
                                    label: "HT-High Tension",
                                    value: "HT",
                                  },
                                  {
                                    label: "LT-Low Tension",
                                    value: "LT",
                                  },
                                ]}
                                className="admim__Addproduct--dropdown  "
                                placeholder="Select Consumer Type"
                                id="consumer_type"
                                styles={{
                                  control: (base, state) => ({
                                    ...base,
                                    padding: "10px 10px",
                                    border: formik.errors.consumer_type
                                      ? "1px solid red"
                                      : "1px solid #B3CDFF80",
                                  }),
                                }}
                              />

                              {formik.errors.consumer_type && (
                                <div className="tariff-error-position">
                                  <p>{formik.errors.consumer_type}</p>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className="form-group mb-3"
                              style={{ position: "relative" }}
                              id="Consumer"
                            >
                              <Tooltip
                                title="Please refer to your EB meter card for this information. "
                                placement="right"
                                classes={tooltipclass}
                                enterTouchDelay={500}
                                arrow
                              >
                                <label
                                  htmlFor="tariff_category"
                                  className="create-label"
                                >
                                  Tariff Category {required}
                                </label>
                              </Tooltip>
                              <Select
                                isDisabled={
                                  formik.values.consumer_type !== null
                                    ? false
                                    : true
                                }
                                isLoading={
                                  categoryOptions.length ? false : true
                                }
                                value={formik.values.tariff_category}
                                onChange={(p) => {
                                  formik.setFieldValue("tariff_category", p);
                                  // formik.setFieldValue("tariff_label", p.value);
                                }}
                                options={categoryOptions}
                                className="admim__Addproduct--dropdown  "
                                placeholder="Select Consumer "
                                id="tariff_category"
                                styles={{
                                  control: (base, state) => ({
                                    ...base,
                                    padding: "10px 10px",
                                    border: formik.errors.tariff_category
                                      ? "1px solid red"
                                      : "1px solid #B3CDFF80",
                                    outline: "none",
                                    boxshadow: "none",
                                  }),
                                  option: (base, state) => ({
                                    ...base,

                                    borderBottom: "1px solid #ddd",

                                    fontSize: "16px",
                                  }),
                                }}
                              />

                              {formik.errors.tariff_category && (
                                <div className="tariff-error-position">
                                  <p>{formik.errors.tariff_category}</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div
                              className="form-group mb-3"
                              style={{ position: "relative" }}
                              id="loadcontent"
                            >
                              <Tooltip
                                title="Please refer to your EB meter card for this information. "
                                placement="right"
                                classes={tooltipclass}
                                enterTouchDelay={500}
                                arrow
                              >
                                <label htmlFor="load" className="create-label">
                                  Connected Load in KW {required}
                                </label>
                              </Tooltip>
                              <input
                                type="number"
                                min={0}
                                disabled={
                                  formik.values.tariff_category !== null
                                    ? false
                                    : true
                                }
                                name="load"
                                placeholder="Enter Load"
                                value={formik.values.load}
                                onChange={formik.handleChange("load")}
                                className={
                                  formik.errors && formik.errors.load
                                    ? "form-control is-invalid transaction_input"
                                    : "form-control transaction_input"
                                }
                              />

                              {formik.errors.load && (
                                <div className="tariff-error-position">
                                  <p>{formik.errors.load}</p>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className="form-group mb-3"
                              style={{ position: "relative" }}
                              id="loadcontent"
                            >
                              <Tooltip
                                title="Please refer to your EB meter card for this information. "
                                placement="right"
                                classes={tooltipclass}
                                enterTouchDelay={500}
                                arrow
                              >
                                <label htmlFor="units" className="create-label">
                                  Total Consumption (Units) {required}
                                </label>
                              </Tooltip>
                              <input
                                type="number"
                                min={0}
                                disabled={
                                  formik.values.load !== "" ? false : true
                                }
                                name="units"
                                placeholder="Enter units"
                                value={formik.values.units}
                                onChange={formik.handleChange("units")}
                                className={
                                  formik.errors && formik.errors.units
                                    ? "form-control is-invalid transaction_input"
                                    : "form-control transaction_input"
                                }
                              />

                              {formik.errors.units && (
                                <div className="tariff-error-position">
                                  <p>{formik.errors.units}</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center justify-content-end mt-2">
                          <Button
                            variant="contained"
                            id="createBtn"
                            className={"createBtn"}
                            onClick={formik.handleSubmit}
                            // disabled={true}
                          >
                            Calculate
                          </Button>
                        </div>
                      </>
                    );
                  }}
                </Formik>
              </div>
            </div>
            {tableData !== "" ? (
              <div className="row " style={{ backgroundColor: "#f3f3f3" }}>
                {/* <div className="col  transac_graph"> */}
                <div className="tab-container  row shadow">
                  <div
                    onClick={() => setActive(0)}
                    className={
                      active === 0
                        ? "col tab-margin tab__active"
                        : "col tab-margin"
                    }
                  >
                    <p className="tab-title">Break-up of charge </p>
                  </div>
                  <div
                    onClick={() => setActive(1)}
                    className={
                      active === 1
                        ? "col tab-margin tab__active"
                        : "col tab-margin"
                    }
                  >
                    <p className="tab-title">
                      Break-up of charges over the years
                    </p>
                  </div>
                  <div
                    onClick={() => setActive(2)}
                    className={
                      active === 2
                        ? "col  tab__active tab-margin"
                        : "col tab-margin "
                    }
                  >
                    <p className="tab-title">
                      Your electricity bill amount goes towards
                    </p>
                  </div>
                  <div
                    onClick={() => setActive(3)}
                    className={active === 3 ? "col  tab__active" : "col "}
                  >
                    <p className="tab-title">
                      Per unit cost of electricity and state-wide comparison
                    </p>
                  </div>
                </div>
                {active === 0 ? (
                  <>
                    <div className="table-responsive mt-5">
                      <table className="table table-bordered" id="price_table">
                        <thead className=" table_header">
                          <tr>
                            <th scope="col">(All values in INR)</th>
                            <th scope="col">
                              As per TNERC (Bi-Monthly without Subsidy)
                            </th>
                            <th scope="col">Subsidy Bill Amount</th>
                            <th scope="col">Payable Bill Amount</th>
                          </tr>
                        </thead>
                        <tbody className="text-center">
                          <tr className="table_sub">
                            <th scope="row">Fixed Charge</th>
                            <td>
                              {tableData != ""
                                ? tableData.amount
                                  ? tableData.amount.fixed.tnerc
                                  : 0
                                : 0}
                            </td>
                            <td>
                              {tableData != ""
                                ? tableData.amount
                                  ? tableData.amount.fixed.subsidy
                                  : 0
                                : 0}
                            </td>
                            <td>
                              {tableData != ""
                                ? tableData.amount
                                  ? tableData.amount.fixed.total
                                  : 0
                                : 0}
                            </td>
                          </tr>
                          <tr className="table_sub">
                            <th scope="row">Variable Charge</th>
                            <td>
                              {tableData != ""
                                ? tableData.amount
                                  ? tableData.amount.variable.tnerc
                                  : 0
                                : 0}
                            </td>
                            <td>
                              {tableData != ""
                                ? tableData.amount
                                  ? tableData.amount.variable.subsidy
                                  : 0
                                : 0}
                            </td>
                            <td>
                              {tableData != ""
                                ? tableData.amount
                                  ? tableData.amount.variable.total
                                  : 0
                                : 0}
                            </td>
                          </tr>
                          <tr className="table_total">
                            <th scope="row">Total</th>
                            <td>
                              &#8377;{" "}
                              {tableData != ""
                                ? tableData.amount
                                  ? tableData.amount.total.tnerc
                                  : 0
                                : 0}
                            </td>
                            <td>
                              &#8377;{" "}
                              {tableData != ""
                                ? tableData.amount
                                  ? tableData.amount.total.subsidy
                                  : 0
                                : 0}
                            </td>
                            <td>
                              &#8377;{" "}
                              {tableData != ""
                                ? tableData.amount
                                  ? tableData.amount.total.total
                                  : 0
                                : 0}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="total-chart">
                      <BarChart
                        title={"Bi-monthly (INR)"}
                        legend={false}
                        data={{
                          labels: ["Subsidy", "Payable Amount(INR)"],
                          datasets: [
                            {
                              data: [
                                tableData != ""
                                  ? tableData.amount
                                    ? tableData.amount.total.subsidy
                                    : 0
                                  : 0,
                                tableData != ""
                                  ? tableData.amount
                                    ? tableData.amount.total.total
                                    : 0
                                  : 0,
                              ],

                              backgroundColor: ["#34e7e4", "#0be881"],
                            },
                          ],
                        }}
                      />
                    </div>
                  </>
                ) : null}

                {active === 1 ? (
                  <>
                    <div className="row mt-4">
                      {
                        <div className="total-chart">
                          <BarChart
                            legend={true}
                            title={"Break-up of charges over the years (INR)"}
                            data={{
                              labels:
                                tableData !== "" &&
                                tableData.comparison.map((row, i) => {
                                  return i < tableData.comparison.length - 1
                                    ? row.year +
                                        "-" +
                                        tableData.comparison[i + 1].year
                                    : row.year === currentYear
                                    ? row.year
                                    : row.year + "-" + currentYear;
                                  // return (i<(tableData.comparison.length-1)
                                  //   ? tableData.comparison[i].year +
                                  //       "-" +
                                  //       tableData.comparison[i + 1].year
                                  //   :tableData.comparison[i].year +"-"+ currentYear;)
                                }),

                              datasets: [
                                {
                                  label: "Fixed Charge",
                                  backgroundColor: ["#ffdd59"],
                                  fillColor: "blue",
                                  data:
                                    tableData !== "" &&
                                    tableData.comparison.map((row) => {
                                      return row.fixed.total;
                                    }),
                                },
                                {
                                  label: "Variable Charge",
                                  backgroundColor: [" #0fbcf9"],
                                  data:
                                    tableData !== "" &&
                                    tableData.comparison.map((row) => {
                                      return row.variable.total;
                                    }),
                                },
                                // {
                                //   label: "Subsidy",
                                //   backgroundColor: ["#34e7e4"],
                                //   data:
                                //     tableData !== "" &&
                                //     tableData.comparison.map((row) => {
                                //       return row.total.subsidy;
                                //     }),
                                // },
                                {
                                  label: "Total",
                                  backgroundColor: ["#0be881"],
                                  data:
                                    tableData !== "" &&
                                    tableData.comparison.map((row) => {
                                      return row.total.total;
                                    }),
                                },
                              ],
                            }}
                          />
                        </div>
                      }
                    </div>

                    <div className="row mt-4">
                      {
                        <div className="total-chart">
                          <BarChart
                            legend={true}
                            title={"Break-up of subsidy over the years (INR)"}
                            data={{
                              labels:
                                tableData !== "" &&
                                tableData.comparison.map((row, i) => {
                                  return i < tableData.comparison.length - 1
                                    ? row.year +
                                        "-" +
                                        tableData.comparison[i + 1].year
                                    : row.year === currentYear
                                    ? row.year
                                    : row.year + "-" + currentYear;
                                  // return (i<(tableData.comparison.length-1)
                                  //   ? tableData.comparison[i].year +
                                  //       "-" +
                                  //       tableData.comparison[i + 1].year
                                  //   :tableData.comparison[i].year +"-"+ currentYear;)
                                }),

                              datasets: [
                                {
                                  label: "Subsidy",
                                  backgroundColor: ["#34e7e4"],
                                  data:
                                    tableData !== "" &&
                                    tableData.comparison.map((row) => {
                                      return parseInt(row.total.subsidy);
                                    }),
                                },
                              ],
                            }}
                          />
                        </div>
                      }
                    </div>
                  </>
                ) : null}

                {active === 2 ? (
                  <>
                    <div className="row">
                      <div className="table-responsive mt-5">
                        <table
                          className="table table-bordered"
                          id="price_table"
                        >
                          <thead className=" table_header">
                            <tr>
                              <th scope="col">
                                Electricity Bill amount (Payable)
                              </th>

                              <td>
                                &#8377;{" "}
                                {tableData !== "" &&
                                  getEPercentage(
                                    tableData.amount.total.total,
                                    tableData.breakup
                                  )[5]}
                              </td>
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            <tr className="table_sub">
                              <th scope="col">Generation Cost</th>
                              <td>
                                &#8377;{" "}
                                {tableData !== "" &&
                                  getEPercentage(
                                    tableData.amount.total.total,
                                    tableData.breakup
                                  )[0]}
                              </td>
                            </tr>
                            <tr className="table_sub">
                              <th scope="col">Power Purchase Cost</th>
                              <td>
                                &#8377;{" "}
                                {tableData !== "" &&
                                  getEPercentage(
                                    tableData.amount.total.total,
                                    tableData.breakup
                                  )[1]}
                              </td>
                            </tr>
                            <tr className="table_sub">
                              <th scope="col">Transmission Cost</th>
                              <td>
                                &#8377;{" "}
                                {tableData !== "" &&
                                  getEPercentage(
                                    tableData.amount.total.total,
                                    tableData.breakup
                                  )[2]}
                              </td>
                            </tr>
                            <tr className="table_sub">
                              <th scope="col">Cost of Line Loss</th>
                              <td>
                                &#8377;{" "}
                                {tableData !== "" &&
                                  getEPercentage(
                                    tableData.amount.total.total,
                                    tableData.breakup
                                  )[4]}
                              </td>
                            </tr>
                            <tr className="table_sub">
                              <th scope="col">Distribution Cost</th>
                              <td>
                                &#8377;{" "}
                                {tableData !== "" &&
                                  getEPercentage(
                                    tableData.amount.total.total,
                                    tableData.breakup
                                  )[3]}
                              </td>
                            </tr>
                            <tr className="table_sub">
                              <th scope="col">Employee Cost</th>
                              <td>
                                &#8377;{" "}
                                {tableData !== "" &&
                                  getEPercentage(
                                    tableData.amount.total.total,
                                    tableData.breakup
                                  )[6]}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="total-chart">
                        <BarChart
                          legend={false}
                          title={"Bi-monthly (INR)"}
                          data={{
                            labels: [
                              "Generation",
                              "Power Purchase",
                              "Transmisson",
                              "Distribution",
                              "Line Loss",
                              "Total",
                            ],
                            datasets: [
                              {
                                data: getPercentage(
                                  tableData != ""
                                    ? tableData.amount
                                      ? tableData.amount.total.total
                                      : 0
                                    : 0,
                                  tableData != ""
                                    ? tableData.breakup
                                      ? tableData.breakup
                                      : 0
                                    : 0
                                ),
                                backgroundColor: [
                                  "#f8c291",
                                  "#fad390",
                                  "#cd84f1",
                                  "#82ccdd",
                                  "#ff5e57",

                                  "#0be881",
                                ],
                              },
                            ],
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {active === 3 ? (
                  <>
                    <div className="row">
                      <div className="table-responsive mt-5">
                        <table
                          className="table table-bordered"
                          id="price_table"
                        >
                          <thead className=" table_header">
                            <tr>
                              <th scope="col">Per unit cost of electricity</th>
                              {tableData
                                ? tableData.stateCmpr.map((td) => {
                                    return (
                                      <th scope="col">
                                        {td.state} ( &#8377; {td.acos} )
                                      </th>
                                    );
                                  })
                                : null}
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            <tr className="table_sub">
                              <th scope="col">Generation Cost</th>
                              {tableData
                                ? tableData.stateCmpr.map((td) => {
                                    return (
                                      <td>
                                        &#8377;{" "}
                                        {tableData !== "" &&
                                          getEPercentage(td.acos, td)[0]}
                                      </td>
                                    );
                                  })
                                : null}
                            </tr>
                            <tr className="table_sub">
                              <th scope="col">Power Purchase Cost</th>
                              {tableData
                                ? tableData.stateCmpr.map((td) => {
                                    return (
                                      <td>
                                        &#8377;{" "}
                                        {tableData !== "" &&
                                          getEPercentage(td.acos, td)[1]}
                                      </td>
                                    );
                                  })
                                : null}
                            </tr>
                            <tr className="table_sub">
                              <th scope="col">Transmission Cost</th>
                              {tableData
                                ? tableData.stateCmpr.map((td) => {
                                    return (
                                      <td>
                                        &#8377;{" "}
                                        {tableData !== "" &&
                                          getEPercentage(td.acos, td)[2]}
                                      </td>
                                    );
                                  })
                                : null}
                            </tr>
                            <tr className="table_sub">
                              <th scope="col">Cost of Line Loss</th>
                              {tableData
                                ? tableData.stateCmpr.map((td) => {
                                    return (
                                      <td>
                                        &#8377;{" "}
                                        {tableData !== "" &&
                                          getEPercentage(td.acos, td)[4]}
                                      </td>
                                    );
                                  })
                                : null}
                            </tr>
                            <tr className="table_sub">
                              <th scope="col">Distribution Cost</th>
                              {tableData
                                ? tableData.stateCmpr.map((td) => {
                                    return (
                                      <td>
                                        &#8377;{" "}
                                        {tableData !== "" &&
                                          getEPercentage(td.acos, td)[3]}
                                      </td>
                                    );
                                  })
                                : null}
                            </tr>
                            <tr className="table_sub">
                              <th scope="col">Employee Cost</th>
                              {tableData
                                ? tableData.stateCmpr.map((td) => {
                                    return (
                                      <td>
                                        &#8377;{" "}
                                        {tableData !== "" &&
                                          getEPercentage(td.acos, td)[6]}
                                      </td>
                                    );
                                  })
                                : null}
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      {tableData ? (
                        <div className="total-chart">
                          {/* <BarChart
                        legend={false}
                        title={"Bi-monthly (INR)"}
                        data={{
                          labels: [
                            "Generation",
                            "Power Purchase",
                            "Transmisson",
                            "Distribution",
                            "Line Loss",
                            "Total",
                          ],
                          datasets: [
                            {
                              data: getPercentage(
                                tableData != ""
                                  ? tableData.amount
                                    ? tableData.amount.total.total
                                    : 0
                                  : 0,
                                tableData != ""
                                  ? tableData.breakup
                                    ? tableData.breakup
                                    : 0
                                  : 0
                              ),
                              backgroundColor: [
                                "#f8c291",
                                "#fad390",
                                "#cd84f1",
                                "#82ccdd",
                                "#ff5e57",

                                "#0be881",
                              ],
                            },
                          ],
                        }}
                      /> */}
                          <LineChart
                            title={"State Level Comparision"}
                            legend={true}
                            data={{
                              labels: [
                                "Generation",
                                "Power Purchase",
                                "Transmisson",
                                "Distribution",
                                "Line Loss",
                              ],
                              datasets: tableData
                                ? tableData.stateCmpr.map((td, i) => {
                                    return {
                                      label: td.state,
                                      // data: getPercentage(
                                      //   tableData != ""
                                      //     ? tableData.amount
                                      //       ? tableData.amount.total.total
                                      //       : 0
                                      //     : 0,
                                      //   td
                                      // ),
                                      data: getEPercentage(td.acos, td),
                                      backgroundColor: backgroundColor[i],
                                      borderColor: backgroundColor[i],
                                      tension: 0.1,
                                      // datalabels: {
                                      //   color: backgroundColor[i]
                                      // }
                                    };
                                  })
                                : null,
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                  </>
                ) : null}
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default Transaction;
