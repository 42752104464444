import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

class BarChart extends Component {
  constructor() {
    super();
    this.state = {
      barChartData: [],
    };
    //EVENT BINDINGS FOR ONCLICK EVENTS ON BUTTONS
    this.change0 = this.change0.bind(this);
    this.change1 = this.change1.bind(this);
    this.change2 = this.change2.bind(this);
    this.change3 = this.change3.bind(this);
  }

  componentDidMount() {
    this.change0();
  }

  change0() {
    this.setState({
      barChartData: {
        labels: [
          "Generation",
          "power",
          "transmission",
          "distribution",
          "Lineloss",
        ],
        datasets: [
          {
            label: "Overall",
            backgroundColor: ["red", "yellow", "blue", "green", "blue", "teal"],
            borderColor: "rgba(255,99,132,1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(255,99,132,0.4)",
            hoverBorderColor: "rgba(255,99,132,1)",
            data: [65, 59, 80, 90, 30],
          },
        ],
      },
    });
  }
  //OUR TWO NEW METHODS FOR  UPDATING THE STATE barChartData BASED ON EVENTS ON CLICK
  change1() {
    this.setState({
      barChartData: {
        labels: [
          "Operation charges",
          "tariff",
          "Depreciation",
          "interest and fin charges",
        ],
        datasets: [
          {
            label: "Distribution",
            backgroundColor: ["red", "yellow", "blue", "green"],
            borderColor: "rgba(255,99,132,1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(255,99,132,0.4)",
            hoverBorderColor: "rgba(255,99,132,1)",
            data: [49, 22, 23, 65],
          },
        ],
      },
    });
  }

  change2() {
    this.setState({
      barChartData: {
        labels: ["Renewable", "Non-Renewable"],
        datasets: [
          {
            label: "Generation",
            backgroundColor: ["red", "yellow"],
            borderColor: "rgba(255,99,132,1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(255,99,132,0.4)",
            hoverBorderColor: "rgba(255,99,132,1)",
            data: [49, 22],
          },
        ],
      },
    });
  }
  change3() {
    this.setState({
      barChartData: {
        labels: ["Renewable", "Non-Renewable"],
        datasets: [
          {
            label: "Power purchase",
            backgroundColor: ["red", "yellow"],
            borderColor: "rgba(255,99,132,1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(255,99,132,0.4)",
            hoverBorderColor: "rgba(255,99,132,1)",
            data: [22, 40],
          },
        ],
      },
    });
  }

  render() {
    return (
      <div>
        <Bar data={this.state.barChartData} />
        //OUR ON CLICK EVENTS ARE DEFINED HERE - ALLOWING THE USER TO SWITCH
        BETWEEN VIEWS AND UPDATE THE STATE OF BarChartData on the fly
        <button onClick={this.change0}>Overall</button>
        <button onClick={this.change2}>Generation</button>
        <button onClick={this.change1}>Distribution</button>
        <button onClick={this.change3}>Power purchase</button>
        {/*<button onClick={this.change2}></button>*/}
      </div>
    );
  }
}

export default BarChart;
