import React from "react";
import datalables from "chartjs-plugin-datalabels";
import { Bar, Chart } from "react-chartjs-2";
import { isNumber } from "lodash";
import { auto } from "@popperjs/core";

// const data = {
//   labels: [
//     "Renewables",
//     "Non-renewables",
//     "PGCIL & SLDC Charges",
//     "operating and manintenance charges",
//     "Deprciation",
//     "interest and finance charges",
//     "Tariff and Non-Tariff Income",
//     "Transmission and Distribution Losses",
//   ],
//   datasets: [
//     {
//       label: "Generation",
//       data: [1, 18, 0, 0, 0, 0, 0, 0],
//       color: ["red", "yellow"],
//       backgroundColor: [
//         "red",
//         "yellow",
//         "blue",
//         "green",
//         "green",
//         "green",
//         "green",
//         "teal",
//       ],
//       borderColor: [
//         "rgba(255, 99, 132, 1)",
//         "rgba(54, 162, 235, 1)",
//         "rgba(255, 206, 86, 1)",
//         "rgba(75, 192, 192, 1)",
//         "rgba(153, 102, 255, 1)",
//         "rgba(255, 159, 64, 1)",
//       ],
//       borderWidth: 1,
//     },
//     {
//       label: "Power",
//       data: [5, 3, 0, 0, 0, 0, 0, 0],
//       color: ["red", "yellow"],
//       backgroundColor: [
//         "red",
//         "yellow",
//         "blue",
//         "green",
//         "green",
//         "green",
//         "green",
//         "teal",
//       ],
//       borderColor: [
//         "rgba(255, 99, 132, 1)",
//         "rgba(54, 162, 235, 1)",
//         "rgba(255, 206, 86, 1)",
//         "rgba(75, 192, 192, 1)",
//         "rgba(153, 102, 255, 1)",
//         "rgba(255, 159, 64, 1)",
//       ],
//       borderWidth: 1,
//     },
//     {
//       label: "Transmission",
//       data: [0, 0, 4, 0, 0, 0, 0, 0],
//       color: ["blue"],
//       backgroundColor: [
//         "red",
//         "yellow",
//         "blue",
//         "green",
//         "green",
//         "green",
//         "green",
//         "teal",
//       ],

//       borderColor: ["rgba(255, 99, 132, 1)"],
//       borderWidth: 1,
//     },
//     {
//       label: "Distribution",
//       data: [0, 0, 0, 6, 0, 0, 0, 0],
//       color: ["green"],
//       backgroundColor: [
//         "red",
//         "yellow",
//         "blue",
//         "green",
//         "green",
//         "green",
//         "green",
//         "teal",
//       ],

//       borderColor: ["rgba(255, 99, 132, 1)"],
//       borderWidth: 1,
//     },
//     {
//       data: [0, 0, 0, 0, 7, 0, 0, 0],
//       color: ["green"],
//       backgroundColor: [
//         "red",
//         "yellow",
//         "blue",
//         "green",
//         "green",
//         "green",
//         "green",
//         "teal",
//       ],

//       borderColor: ["rgba(255, 99, 132, 1)"],
//       borderWidth: 1,
//     },
//     {
//       data: [0, 0, 0, 0, 0, 5, 0, 0],
//       color: ["green"],
//       backgroundColor: [
//         "red",
//         "yellow",
//         "blue",
//         "green",
//         "green",
//         "green",
//         "green",
//         "teal",
//       ],

//       borderColor: ["rgba(255, 99, 132, 1)"],
//       borderWidth: 1,
//     },
//     {
//       label: "Distribution",
//       data: [0, 0, 0, 0, 0, 0, -3, 0],
//       color: ["green"],
//       backgroundColor: [
//         "red",
//         "yellow",
//         "blue",
//         "green",
//         "green",
//         "green",
//         "green",
//         "teal",
//       ],
//       borderColor: [
//         "rgba(255, 99, 132, 1)",
//         "rgba(54, 162, 235, 1)",
//         "rgba(255, 206, 86, 1)",
//         "rgba(75, 192, 192, 1)",
//         "rgba(153, 102, 255, 1)",
//         "rgba(255, 159, 64, 1)",
//       ],
//       borderWidth: 1,
//     },
//     {
//       label: "Line Losses",
//       data: [0, 0, 0, 0, 0, 0, 0, 5],
//       color: ["teal"],
//       backgroundColor: [
//         "red",
//         "yellow",
//         "blue",
//         "green",
//         "green",
//         "green",
//         "green",
//         "teal",
//       ],
//       borderColor: ["rgba(255, 159, 64, 1)"],
//       borderWidth: 1,
//     },
//   ],
// };

// const options = {
//   indexAxis: "y",
//   scales: {
//     x: {
//       display: true,
//       //   title: {
//       //     display: true,
//       //     text: "Date",
//       //   },
//       ticks: {
//         major: {
//           enabled: true,
//         },
//         min: 5,
//         max: 30,
//         stepSize: 0.5,

//         callback: (label, index, labels) => {
//           return label + "%";
//         },
//         color: (context) => context.tick && context.tick.major && "#FF0000",
//         font: function (context) {
//           if (context.tick && context.tick.major) {
//             return {
//               weight: "bold",
//             };
//           }
//         },
//       },
//     },
//   },
//   // Elements options apply to all of the options unless overridden in a dataset
//   // In this case, we are setting the border of each horizontal bar to be 2px wide
//   elements: {
//     bar: {
//       borderWidth: 2,
//     },
//   },
//   responsive: true,
//   plugins: {
//     legend: {
//       position: "right",
//     },
//     title: {
//       display: true,
//       text: "Tariff 2018",
//     },
//   },
// };

// const data = [
//   { x: "Renewable", gen: 7.2, pur: 18 },
//   { x: "Non-Renewable", gen: 18, pur: 38.1 },
//   { x: "PGCIL & SLDC Charges", trans: 5.5 },
// ];
// const data2 = [
//   { x: "0perating & Maintenance", dis: 11.3 },
//   { x: "Depreciation", dis: 2.4 },
//   { x: "Interest and Financial Charges", dis: 5.3 },
//   { x: "Tariff and Income", dis: -3.1 },
// ];
// const data3 = [{ x: "LineLoss", loss: 13.8 }];
// const cfg = {
//   type: "bar",
//   data: {
//     labels: [
//       "Renewable",
//       "Non-Renewable",
//       "PGCIL & SLDC Charges",
//       "0perating & Maintenance",
//       "Depreciation",
//       "Interest and Financial Charges",
//       "Tariff and Income",
//       "Line Losses",
//     ],
//     datasets: [
//       {
//         label: "Generation",
//         data: [
//           {
//             x: "renewable",
//             gen: 10,
//             pur: 2,
//           },
//           {
//             x: "non-renewable",
//             gen: 8,
//             pur: 2,
//           },
//         ],
//         parsing: {
//           yAxisKey: "gen",
//           yAxisKey: "pur",
//         },
//       },
//     //   {
//     //     label: "Power purchase",
//     //     data: data,
//     //     parsing: {
//     //       yAxisKey: "pur",
//     //     },
//     //   },
//       {
//         label: "Transmission",
//         data: [{
//             x:"PGCIL & SLDC Charges"
//             ,trans:2
//         }],
//         parsing: {
//           yAxisKey: "trans",
//         },
//       },
//       {
//         label: "Distribution",
//         data: data2,
//         parsing: {
//           yAxisKey: "dis",
//         },
//       },
//       {
//         label: "Line Loss",
//         data: data3,
//         parsing: {
//           yAxisKey: "loss",
//         },
//       },
//     ],
//   },
// };
// const data2 = [{ x: "pgcil", trans: 100 }];

// const data4 = [
//   { x: "expenses", dis: 10 },
//   { x: "depreciation", dis: 20 },
//   { x: "tariff", dis: 50 },
//   { x: "finance", dis: 60 },
// ];
// const data5 = [{ x: "losses", loss: 10 }];

// const data = [
//   { y: "Renewables", gen: 1, pur: 7 },
//   { y: "Non-Renewables", gen: 18, pur: 38 },
//   { y: "PGCIL & SLDC Charges", trans: 5 },
//   { y: "Operating and Maintenance Expenses", dis: 11 },
//   { y: "Depreciation", dis: 2 },
//   { y: "Tariff and Non-Tariff Income", dis: -3 },
//   { y: "Interest and Finance Charges", dis: 5 },

//   { y: "Includes Transmission and Distribution Losses", loss: 14 },
// ];

const options = {
  maintainAspectRatio: false,
  indexAxis: "y",
  layout: {
    padding: 10,
  },

  scales: {
    x: {
      ticks: {
        major: {
          enabled: true,
        },
        font: {
          size: 15,
        },

        callback: (label, index, labels) => {
          return label + "%";
        },
      },

      barPercentage: 1,
    },
    y: {
      ticks: {
        major: {
          enabled: true,
        },

        font: {
          size: 12,
        },
      },

      barPercentage: 1,
    },
  },

  plugins: {
    legend: {
      position: "top",
    },

    title: {
      display: true,
      text: "Per Unit Cost Towards",
      font: {
        size: 20,
      },
    },
    tooltip: {
      enabled: true,

      callbacks: {
        label: function (context) {
          // console.log({ context });
          var label = context.dataset.label || "";
          // console.log({ label });
          // if (label) {
          //   label += ": ";
          // }
          if (
            context.parsed.x !== null &&
            context.parsed.x !== 0 &&
            context.parsed.x !== undefined
          ) {
            // console.log({ x: context.parsed.x });
            label += ":" + context.parsed.x + "%";
          }
          return label;
        },
      },
    },

    datalabels: {
      enabled: true,
      anchor: "end",
      align: "right",
      color: "black",
      clamp: true,

      font: {
        weight: "bold",
        size: 13,
      },

      formatter: function (value, context) {
        // console.log({
        //   context,
        //   value,
        //   data: context.chart.data.labels,
        //   index: context.dataIndex,
        //   key: value[context.dataset.parsing.xAxisKey],
        //   data2: context.chart.data.labels[context.dataIndex],
        // });
        if (value[context.dataset.parsing.xAxisKey] !== undefined) {
          return value[context.dataset.parsing.xAxisKey] + "%";
        } else return "";
      },
    },
  },
};

const HorizontalBarChart = ({ data }) => {
  // console.log(data);
  const cfg = {
    type: "bar",
    data: {
      labels: [
        "Renewables",
        "Non-Renewables",
        "PGCIL & SLDC Charges",
        "Operating and Maintenance Expenses",
        "Depreciation",
        "Interest and Finance Charges",
        "Tariff and Non-Tariff Income",
        "Includes Transmission and Distribution Losses",
      ],
      datasets: [
        {
          label: "Generation",
          data: data,

          parsing: {
            xAxisKey: "gen",
          },
          backgroundColor: ["#e34850"],
        },
        {
          label: "Power Purchase",
          data: data,
          parsing: {
            xAxisKey: "pur",
          },
          color: ["yellow"],
          backgroundColor: ["#ffa600"],
        },
        {
          label: "Transmission",
          data: data,
          parsing: {
            xAxisKey: "trans",
          },
          color: ["blue"],
          backgroundColor: ["#4646c6"],
        },
        {
          label: "Distribution",
          data: data,
          parsing: {
            xAxisKey: "dis",
          },
          backgroundColor: ["#007d79"],
        },
        {
          label: "Line Loss",
          data: data,
          parsing: {
            xAxisKey: "loss",
          },
          backgroundColor: ["#bc1c74"],
        },
      ],
    },
  };

  return (
    <>
      <Bar
        plugins={[datalables]}
        data={cfg.data}
        options={options}
        height={600}
        width={150}
      />
    </>
  );
};

export default HorizontalBarChart;
