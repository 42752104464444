import React, { useState, useEffect } from "react";
import HorizontalBarChart from "./HorizontalBar";
import DoughnutChart from "./Doughnut";
import BarChart from "./barchart2";
import Select from "react-select";
import "../assets/css/dashboard.css";
import Global from "../global";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Loader from "react-loader-spinner";

const dropDownCustom = {
  control: (base, state) => ({
    ...base,
    background: "#fff",
    padding: "7px",
    border: "1px solid rgb(184, 184, 184)",
    outline: "none",
    "&:active": {
      border: state.isFocused ? "1px solid #B3CDFF80" : "1px solid #B3CDFF80",
      outline: "none",
      boxShadow: "none",
    },
    "&:hover": {
      border: state.isFocused ? "1px solid #B3CDFF80" : "1px solid #B3CDFF80",
      outline: "none",
      boxShadow: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    border: "none",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? "#eee" : "#fff",
    padding: "8px",
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    zIndex: 9999,
    color: state.isFocused ? "#000" : "#000",
    "&:hover": {
      backgroundColor: state.isFocused ? "#eee" : "#fff",
    },
  }),
  menuList: (base) => ({
    ...base,
    border: "1px solid #eee",
  }),
};
const Dashboard = () => {
  const [state, setState] = useState({
    label: "Tamil Nadu",
    value: "Tamil Nadu",
  });
  const [stateOptions, setStateOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [year, setYear] = useState(null);
  let totaldata = {};
  let genData = {};
  let purData = {};
  let transLossData = {};
  let distData = {};
  let barData = {};
  const config2 = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  useEffect(() => {
    // const data = states1();
    // let arr = [];
    // data.map((s) => {
    //   arr.push({ label: s.state, value: s.state });
    // });

    async function getData() {
      setLoading(true);
      try {
        const response = await axios.get(
          Global.base_url + "dashboard/getData",
          {
            params: {
              state: state.value,
            },
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        // console.log(response);

        if (response.status === 200) {
          setStateOptions(response.data.tariffOptions.state);
          setYearOptions(response.data.tariffOptions.year);
          setYear({
            label: response.data.tariffOptions.year[0],
            value: response.data.tariffOptions.year[0],
          });

          setData(response.data);
          return setLoading(false);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.status !== undefined
        ) {
          //  console.log("error");
          // return history.replace("/");
        }
      }
    }
    getData();
  }, [state]);
  const getOptions = (dataarr) => {
    let options = [];
    dataarr.map((data) => {
      options.push({ label: data, value: data });
    });
    //  console.log({ options });
    return options;
  };
  if (data !== null) {
    totaldata = {
      labels: ["Generation", "Power Purchase", "Network Cost", "Line Losses"],
      datasets: [
        {
          data: [
            parseFloat(data.generation.totalGeneration.toFixed(2)),
            parseFloat(data.powerPurchase.totalPurchace.toFixed(2)),
            parseFloat(
              (
                data.distribution.unit_distribution +
                data.transmission.unitTrans
              ).toFixed(2)
            ),
            parseFloat(data.lineloss.lineLoss.toFixed(2)),
          ],

          backgroundColor: ["#e34850", "#ffa600", "#b79900", "#bc5090"],
        },
      ],
    };
    genData = {
      labels: ["Renewable", "Non-Renewable"],
      datasets: [
        {
          data: [
            parseFloat(data.generation.renewable.toFixed(2)),
            parseFloat(data.generation.non_renewable.toFixed(2)),
          ],
          // backgroundColor: ["#e34850", "#ffa600"],
          backgroundColor: ["#85d044", "#f1c21b"],
        },
      ],
    };
    purData = {
      labels: ["Renewable", "Non-Renewable"],
      datasets: [
        {
          data: [
            parseFloat(data.powerPurchase.renewable.toFixed(2)),
            parseFloat(data.powerPurchase.non_renewable.toFixed(2)),
          ],
          // backgroundColor: ["#e34850", "#ffa600"],
          backgroundColor: ["#85d044", "#f1c21b"],
        },
      ],
    };
    transLossData = {
      labels: ["Transmission", "Line Loss"],
      datasets: [
        {
          data: [
            parseFloat(data.transmission.unitTrans.toFixed(2)),
            parseFloat(data.lineloss.lineLoss.toFixed(2)),
          ],
          backgroundColor: ["#4646c6", "#bc1c74"],
        },
      ],
    };
    distData = {
      labels: [
        "Operating & Maintenance Expense",
        "Depreciation",
        "Interest & Finance Charges",
        "Tariff and Non-Tariff Income",
      ],
      datasets: [
        {
          data: [
            parseFloat(data.distribution.unit_opr_main.toFixed(2)),
            parseFloat(data.distribution.unit_depreciation.toFixed(2)),
            parseFloat(data.distribution.unit_interest.toFixed(2)),
            parseFloat(data.distribution.unit_income.toFixed(2)),
          ],

          backgroundColor: ["#46C7C7", "#50C878", "#16878c", "#6B8E23"],
        },
      ],
    };
    barData = [
      {
        y: "Renewables",
        gen: parseFloat(data.generation.renewPercent.toFixed(2)),
        pur: parseFloat(data.powerPurchase.renewPercent.toFixed(2)),
      },
      {
        y: "Non-Renewables",
        gen: parseFloat(data.generation.nrenewPercent.toFixed(2)),
        pur: parseFloat(data.powerPurchase.nrenewPercent.toFixed(2)),
      },
      {
        y: "PGCIL & SLDC Charges",
        trans: parseFloat(data.transmission.transPercent.toFixed(2)),
      },
      {
        y: "Operating and Maintenance Expenses",
        dis: parseFloat(data.distribution.opr_main_Percent.toFixed(2)),
      },
      {
        y: "Depreciation",
        dis: parseFloat(data.distribution.depreciationPercent.toFixed(2)),
      },
      {
        y: "Tariff and Non-Tariff Income",
        dis: parseFloat(data.distribution.incomePercent.toFixed(2)),
      },
      {
        y: "Interest and Finance Charges",
        dis: parseFloat(data.distribution.interest_Percent.toFixed(2)),
      },

      {
        y: "Includes Transmission and Distribution Losses",
        loss: parseFloat(data.lineloss.lossPercent.toFixed(2)),
      },
    ];
  }
  // console.log({ data, yearOptions, stateOptions });
  return (
    <>
      <div
        className="w-100 dashboard-main p-3"
        style={{ backgroundColor: "#f3f3f3" }}
      >
        {loading ? (
          <div className="loader-position">
            <Loader
              type="TailSpin"
              color="#E37D20"
              height={80}
              width={80}
              //3 secs
            />
          </div>
        ) : (
          <>
            <div className="row  align-items-center dashboard-container">
              <div className="col-7 ">
                <h2 className="header-text">{`${state.value} Tariff ${
                  year ? year.value : null
                }`}</h2>
              </div>
              <div className="col-5 select-container ">
                <div className="content-width-select">
                  <Select
                    isDisabled={false}
                    options={
                      stateOptions.length
                        ? getOptions(stateOptions)
                        : [
                            {
                              label: "Tamil Nadu",
                              value: "Tamil Nadu",
                            },
                          ]
                    }
                    defaultValue={"Tamil Nadu"}
                    styles={dropDownCustom}
                    onChange={(value) => setState(value)}
                    value={state}
                    className="select-box"
                    placeholder="State"
                  />
                </div>
                <div className="content-width-select">
                  <Select
                    options={
                      yearOptions.length ? getOptions(yearOptions) : null
                    }
                    styles={dropDownCustom}
                    onChange={(value) => setYear(value)}
                    value={year}
                    isDisabled={true}
                    className="select-box"
                    placeholder="Year"
                  />
                </div>
              </div>
            </div>
            <hr className="line mb-5"></hr>
            <div className="row   justify-content-between ">
              <div
                className="col-xs-12 col-sm-12 text-center align-items-center inner-chart total-chart"
                style={{ width: "65%" }}
              >
                <DoughnutChart
                  height={300}
                  width={150}
                  data={totaldata}
                  title={"What makes your Electricity Price?"}
                  subtitle={
                    data !== null
                      ? `ACOS ${data.generation.acos.toFixed(2)} INR `
                      : null
                  }
                  position={"right"}
                />
              </div>
              <div
                className="col-xs-12 col-sm-12  total-chart"
                style={{ width: "30%" }}
              >
                <DoughnutChart
                  height={300}
                  width={150}
                  data={distData}
                  title={"Distribution"}
                  position={"top"}
                />
              </div>
            </div>

            <div className="row  mt-5 justify-content-between align-items-center">
              <div className="col-xs-12 col-sm-12 total-chart content-width ">
                <DoughnutChart
                  height={300}
                  width={150}
                  data={genData}
                  title={"Generation"}
                  position={"right"}
                />
              </div>
              <div
                className="col-xs-12 col-sm-12  total-chart content-width"
                style={{
                  marginLeft: "5%",
                  marginRight: "5%",
                }}
              >
                <DoughnutChart
                  height={300}
                  width={150}
                  data={transLossData}
                  title={"Transmission and Line Loss"}
                  position={"right"}
                />
              </div>
              <div className="col-xs-12 col-sm-12 total-chart content-width ">
                <DoughnutChart
                  height={300}
                  width={150}
                  data={purData}
                  title={"Power Purchase"}
                  position={"right"}
                />
              </div>
            </div>
            <div className="row unit-chart ">
              <div className="col">
                <HorizontalBarChart data={barData} />
              </div>
            </div>
          </>
        )}
        {/* <BarChart /> */}

        {/*  */}
      </div>
    </>
  );
};

export default Dashboard;
