import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Layout from "./screens/Layout";

import Main from "./screens/Main";
import Login from "./screens/Login";
import Signup from "./screens/Signup";
import Dashboard from "./screens/Dashboard";
import Transaction from "./screens/Transaction";

function App() {
  return (
    <div className="container-fluid app" style={{ padding: 0 }}>
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route path={"/signup"} component={Signup} />
            <Route exact path={"/home"} component={Layout} />
            <Route exact path={"/dashboard_tariff"} component={Dashboard} />
            <Route exact path={"/Transaction"} component={Transaction} />
            <Route exact path="/dashboard_iframe">
              {(props) => {
                return (
                  <iframe
                    src="/dashboard_tariff"
                    style={{
                      border: "3px solid black",
                      width: "100%",
                      height: "100vh",
                      padding: "10px",
                      margin: 0,
                    }}
                  />
                );
              }}
            </Route>
            <Route exact path="/Transaction_iframe">
              {(props) => {
                return (
                  <iframe
                    src="/Transaction"
                    style={{
                      border: "3px solid black",
                      width: "100%",
                      height: "100vh",
                      padding: "10px",
                      margin: 0,
                    }}
                  />
                );
              }}
            </Route>
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
