import React, { useState, useEffect } from "react";

import "../assets/css/dashboard.css";
import "../assets/css/costview.css";
import { Button } from "@material-ui/core";
import { BsThreeDotsVertical, BsPlusCircleFill } from "react-icons/bs";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaWindowClose } from "react-icons/fa";
import { MdKeyboardBackspace } from "react-icons/md";
import { IconContext } from "react-icons";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import Global from "../global";
import axios from "axios";
import moment from "moment";
import { useHistory } from "react-router-dom";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Collapse,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { constant } from "lodash";

const CostReport = (props) => {
  const [costData, setCostData] = useState({});
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("cag_key")}`,
    },
  };

  const config2 = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("cag_key")}`,
      "Content-Type": "application/json",
    },
  };
  const history = useHistory();
  // console.log("costView ", props);
  useEffect(() => {
    async function getData() {
      try {
        const response = await axios.get(Global.base_url + "tariff/costview", {
          params: {
            tid: props.data.tid,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("cag_key")}`,
          },
        });
        //   console.log(response);
        if (response.status === 200) {
          setCostData(response.data.data[0]);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.status !== undefined
        ) {
          //    console.log("error");
          return history.replace("/");
        }
      }
    }
    getData();
  }, []);

  return (
    <div className="w-100">
      <div className="row align-items-center mb-3">
        <div className="col-12">
          <div className="d-flex flex-row align-items-center  justify-content-start">
            <div
              className="mr-3"
              style={{ cursor: "pointer" }}
              onClick={() => props.close(false)}
            >
              <IconContext.Provider
                value={{
                  size: "40px",
                  color: "#6a707e",
                }}
              >
                <MdKeyboardBackspace />
              </IconContext.Provider>
            </div>

            <h4 className="header-text " style={{ marginLeft: "20px" }}>
              Cost View
            </h4>
          </div>
        </div>
        {/* <div className="col-3">
              
            </div>*/}
      </div>{" "}
      <div style={{ marginTop: "30px" }}>
        <Card style={{ backgroundColor: "white" }}>
          <CardHeader
            className="costview-headercard"
            //style={{ backgroundColor: "#bdbdbd" }}
          >
            <h2 className="mb-0" style={{ textAlign: "center" }}>
              TARIFF ORDER {props.data.year}
            </h2>
          </CardHeader>
        </Card>
      </div>
      <Row style={{ marginTop: "30px" }}>
        <div className="col">
          <Card
            className="costview-cardContent"
            // style={{ border: "1px solid black", padding: "10px" }}
          >
            <Table className=" table-flush " responsive>
              <thead>
                <tr className="costview-header costview-table">
                  <td colSpan={2}></td>
                  <td>TOTAL</td>
                  <td>PER UNIT</td>
                </tr>
              </thead>
              <tbody>
                <tr className="costview-table">
                  <td colSpan={2}> Generation Cost</td>
                  <td>{costData.total_gencost} INR in Crs</td>
                  <td>{costData.unit_gencost} INR </td>
                </tr>
                <tr className="costview-table">
                  <td colSpan={2}> Total Power Purchase Cost</td>
                  <td>{costData.total_purcost} INR in Crs</td>
                  <td>{costData.unit_purcost} INR </td>
                </tr>
                <tr className="costview-table">
                  <td colSpan={2}> Transmission Cost</td>
                  <td>{costData.total_transcost} INR in Crs</td>
                  <td>{costData.unit_transcost} INR </td>
                </tr>
                <tr className="costview-table">
                  <td colSpan={2}> Distribution Cost</td>
                  <td>{costData.total_distrcost} INR in Crs</td>
                  <td>{costData.unit_distrcost} INR </td>
                </tr>
                <tr className="costview-table">
                  <td
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#393737",
                      color: "#fff",
                    }}
                    colSpan={2}
                  >
                    Aggregate Revenue Requirement
                  </td>
                  <td
                    style={{
                      backgroundColor: "#393737",
                      color: "#fff",
                    }}
                  >
                    {costData.total_arr} INR in Crs
                  </td>
                  <td
                    style={{
                      backgroundColor: "#393737",
                      color: "#fff",
                    }}
                  >
                    {costData.unit_arr} INR
                  </td>
                </tr>
                <tr className="costview-table">
                  <td colSpan={2}>Total Energy Sales of the year</td>
                  <td>{costData.tot_energysale} INR in Crs</td>
                  <td></td>
                </tr>
                <tr className="costview-table" style={{ borderBottom: 0 }}>
                  <td colSpan={2}></td>
                  <td style={{ fontWeight: "bold" }}>
                    PER UNIT COST OF LINE LOSSES
                  </td>
                  <td>{costData.unit_lineloss} INR</td>
                </tr>
                <tr
                  className="costview-table"
                  style={{ borderBottom: 0, borderTop: "1px solid" }}
                >
                  <td colSpan={2}></td>
                  <td style={{ fontWeight: "bold" }}>AVERAGE COST OF SUPPLY</td>
                  <td>{costData.acos} INR</td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </div>
      </Row>
      <Row style={{ marginTop: "30px" }}>
        <div className="col">
          <Card
            className="costview-cardContent"
            //   style={{ border: "1px solid black", padding: "10px" }}
          >
            <Table className="align-items-center table-flush " responsive>
              <thead>
                <tr className="costview-header costview-table">
                  <td colSpan={2}>DISTRIBUTION COSTS</td>
                  <td>TOTAL</td>
                  <td>PER UNIT</td>
                </tr>
              </thead>
              <tbody>
                <tr className="costview-table">
                  <td colSpan={2}>Operating & Maintenance Expenses</td>
                  <td>{costData.total_om_exp} INR in Crs</td>
                  <td>{costData.unit_om_exp} INR </td>
                </tr>
                <tr className="costview-table">
                  <td colSpan={2}>Depreciation</td>
                  <td>{costData.total_depreciation} INR in Crs</td>

                  <td> {costData.unit_depreciation} INR</td>
                </tr>
                <tr className="costview-table">
                  <td colSpan={2}>Interest and Finance Charges</td>
                  <td> {costData.total_interest} INR in Crs</td>
                  <td> {costData.unit_interest} INR</td>
                </tr>
                <tr className="costview-table" style={{ borderBottom: 0 }}>
                  <td colSpan={2}>Total Tariff and Non-Tariff Income</td>
                  <td>({costData.total_income}) INR in Crs</td>
                  <td> -{costData.unit_income} INR</td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </div>
      </Row>
    </div>
  );
};

export default CostReport;
