import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import "../assets/css/dashboard.css";
import { Button } from "@material-ui/core";
import { BsThreeDotsVertical, BsPlusCircleFill } from "react-icons/bs";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaWindowClose } from "react-icons/fa";
import { MdKeyboardBackspace } from "react-icons/md";
import { IconContext } from "react-icons";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import Loader from "react-loader-spinner";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Collapse,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import NewSlab from "./NewSlab";
import EditSlab from "./EditSlab";

import Global from "../global";
import axios from "axios";
import moment from "moment";

const data = [
  {
    slab: "SB#0001",
    state: "Tamil Nadu",
    year: "2000",
    Effective_date: "16-JUN-2021",
    Created_Date: "12-JUN-2021",
  },
  {
    slab: "SB#0002",
    Created_Date: "12-JUN-2021",
    state: "Tamil Nadu",
    year: "2000",
    Effective_date: "16-JUN-2021",
  },
];

function ConfirmModal(props) {
  //console.log("modal opened", props.data);
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("cag_key")}`,
    },
  };

  const config2 = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("cag_key")}`,
      "Content-Type": "application/json",
    },
  };
  const deleteTariff = async (sid) => {
    try {
      const response = await axios.post(
        Global.base_url + "slab/delete",
        {
          sid,
        },
        config
      );
      //   console.log(response);
      if (response.status === 200) {
        props.onHide();
        return props.toaster(1, "Successfully Deleted");
      }
    } catch (error) {
      //  console.log(error);
      if (
        (error.response && error.response.status === 401) ||
        (error.response.status === 400 && error.response.status !== undefined)
      ) {
        //   console.log("error");

        props.onHide();
        return props.toaster(0, "Deletion Failed");
      }
    }
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="deleteModal-header">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="deleteModal-headerText"
        >
          Confirmation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="deleteModal-body">
        <h4 className="deleteModal-bodyText">
          Do you want to Delete Slab# {props.data} ?
        </h4>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="contained"
          id="modalDeleteBtn"
          className={"modalDeleteBtn"}
          onClick={() => deleteTariff(props.data)}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          id="modalcloseBtn"
          className={"modalcloseBtn"}
          onClick={props.onHide}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const Slab = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("cag_key")}`,
    },
  };
  //  console.log(config);
  const config2 = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("cag_key")}`,
      "Content-Type": "application/json",
    },
  };
  const [loading, setLoading] = useState(true);
  const [slabList, setList] = useState([]);
  const [add, setAdd] = useState(false);

  // const [costView, setCostView] = useState({
  //   state: false,
  //   index: "",
  //   data: "",
  // });
  const [editView, setEditView] = useState({
    state: false,
    index: "",
    data: "",
  });
  const [expand, setExpand] = useState({
    state: false,
    index: "",
    sid: "",
  });

  const deleteTariff = async (sid) => {
    //  console.log("dekete id", sid);
    setExpand({ ...expand, sid });
    setModalShow(true);
  };

  const toaster = (status, msg) => {
    if (status) {
      toast.success(msg);
    } else {
      toast.error(msg);
    }
  };

  useEffect(() => {
    async function getData() {
      try {
        const response = await axios.get(Global.base_url + "slab/get", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("cag_key")}`,
          },
        });
        if (response.status === 200) {
          setList(response.data.data);
          return setLoading(false);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.status !== undefined
        ) {
          //   console.log("error");
          // return props.history.replace("/");
        }
      }
    }
    getData();
  }, [modalShow, editView.state, add]);
  return (
    <>
      {add ? (
        <NewSlab close={setAdd} toaster={toaster} />
      ) : editView.state ? (
        <EditSlab close={setEditView} data={editView.data} toaster={toaster} />
      ) : loading ? (
        <div className="loader-position">
          <Loader
            type="TailSpin"
            color="#E37D20"
            height={80}
            width={80}
            //3 secs
          />
        </div>
      ) : (
        <div className="w-100">
          <ConfirmModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            toaster={toaster}
            data={expand.sid}
          />
          <ToastContainer />
          <div className="row align-items-center mb-3">
            <div className="col-9">
              <h4 className="header-text">Slab Rates</h4>
            </div>
            <div className="col-3">
              <div className="d-flex flex-row align-items-center  justify-content-end">
                <div
                  className="mr-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => setAdd(true)}
                >
                  <IconContext.Provider
                    value={{
                      size: "40px",
                      color: "#42BD41",
                    }}
                  >
                    <BsPlusCircleFill />
                  </IconContext.Provider>
                </div>
                <div className="ml-4" style={{ marginLeft: "15px" }}>
                  Add
                </div>
              </div>
            </div>
          </div>

          <div className="table-responsive table-responsive-md table-responsive-sm main-table">
            <table className="table  table-data">
              <thead>
                <tr className="table-header-text">
                  <th scope="col">Slab#</th>
                  <th scope="col">State</th>
                  <th scope="col">Year</th>
                  <th scope="col">Created Date</th>

                  <th scope="col">Effective On</th>

                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {slabList.map((tdata, i) => {
                  return (
                    <tr className="table-data-row" key={i}>
                      <td>{tdata.sid}</td>
                      <td>{tdata.state}</td>
                      <td>{tdata.year}</td>
                      <td>{moment(tdata.create_date).format("DD-MM-YYYY")}</td>

                      <td>
                        {moment(tdata.effective_date).format("DD-MM-YYYY")}
                      </td>
                      {/* <td>
                        <Button
                          variant="contained"
                          id="costViewBtn"
                          className={
                            tdata.disabled
                              ? "costViewBtn-disabled"
                              : "costViewBtn"
                          }
                          onClick={() => {
                            setCostView({
                              index: i,
                              data: tdata,
                              state: true,
                            });
                          }}
                          disabled={tdata.disabled}
                        >
                          View
                        </Button>
                      </td>*/}
                      <td>
                        <div
                          style={{ position: "relative", cursor: "pointer" }}
                          onClick={() => {
                            setExpand({
                              state: !expand.state,
                              index: i,
                              sid: tdata.sid,
                            });
                          }}
                        >
                          <IconContext.Provider
                            value={{
                              size: "25px",
                              color: "black",
                            }}
                          >
                            <BsThreeDotsVertical />
                          </IconContext.Provider>
                          {expand.state && i === expand.index && (
                            <div
                              className="dropdown-container"
                              onBlur={() => {
                                setExpand({
                                  state: !expand.state,
                                  index: i,
                                });
                              }}
                            >
                              <div
                                className="dropdown-item"
                                onClick={() =>
                                  setEditView({
                                    index: i,
                                    data: tdata,
                                    state: true,
                                  })
                                }
                              >
                                Edit
                              </div>
                              <div
                                className="dropdown-item"
                                onClick={() => deleteTariff(tdata.sid)}
                              >
                                Delete
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default Slab;
