import React, { useEffect, useState } from "react";

import "../assets/css/dashboard.css";
import { Button } from "@material-ui/core";
import { BsThreeDotsVertical, BsPlusCircleFill } from "react-icons/bs";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { FaWindowClose } from "react-icons/fa";
import { MdKeyboardBackspace, MdSignalCellularNull } from "react-icons/md";
import { IconContext } from "react-icons";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import Global from "../global";
import axios from "axios";
import "../assets/css/slabrates.css";
import { toast, ToastContainer } from "react-toastify";
import Loader from "react-loader-spinner";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Collapse,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import "../assets/css/slab.css";

import { states1, year1, stateErc } from "../global";
import moment from "moment";

const validationSchema = yup.object({
  hightension: yup.object().shape({
    HT1A: yup.object({
      energy_charges: yup.number().required("Required"),
      demand_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_demand: yup.number().required("Required"),
    }),
    HT1B: yup.object({
      energy_charges: yup.number().required("Required"),
      demand_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_demand: yup.number().required("Required"),
    }),
    HT2A: yup.object({
      energy_charges: yup.number().required("Required"),
      demand_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_demand: yup.number().required("Required"),
    }),

    HT2B: yup.object({
      energy_charges: yup.number().required("Required"),
      demand_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_demand: yup.number().required("Required"),
    }),
    HT3: yup.object({
      energy_charges: yup.number().required("Required"),
      demand_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_demand: yup.number().required("Required"),
    }),
    HT4: yup.object({
      energy_charges: yup.number().required("Required"),
      demand_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_demand: yup.number().required("Required"),
    }),
    HT5: yup.object({
      energy_charges: yup.number().required("Required"),
      demand_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_demand: yup.number().required("Required"),
    }),
  }),
  lowtension: yup.object({
    LT1Aa: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT1Ab: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT1Ac: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT1Ad: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT1Ae: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT1Af: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT1Ag: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT1Ah: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT1Ai: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT1Aj: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT1Ba: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT1Bb: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT1C: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT2A: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT2Ba: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT2Bb: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT2Ca: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT2Cb: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT3A1a: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT3A1b: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT3A2a: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT3A2b: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT3A2c: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT3A2d: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT3A2e: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT3B: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT4A: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT4B: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT5A: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT5B: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT6A: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
    LT6B: yup.object().shape({
      energy_charges: yup.number().required("Required"),
      fixed_charges: yup.number().required("Required"),
      subsidy_energy: yup.number().required("Required"),
      subsidy_fixed: yup.number().required("Required"),
    }),
  }),
});

const required = <span style={{ color: "red" }}>*</span>;
const styles = {
  border: "1px solid rgba(0, 0, 0, 0.05)",
};
const NewSlab = (props) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("cag_key")}`,
    },
  };
  const config2 = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("cag_key")}`,
      "Content-Type": "application/json",
    },
  };
  const [formShow, setFormShow] = useState(false);
  const [slabData, setValues] = useState({});
  const [next, setNext] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [editData, setEditData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statesOption, setStates] = useState([]);
  const [yearOption, setYear] = useState([]);
  useEffect(() => {
    const data = states1();
    let arr = [];
    data.map((s) => {
      arr.push({ label: s.state, value: s.state });
    });

    async function getData() {
      try {
        const response = await axios.get(Global.base_url + "slab/getData", {
          params: {
            sid: props.data.sid,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("cag_key")}`,
          },
        });
        //  console.log(response);
        if (response.status === 200) {
          setEditData(response.data);
          setStates(arr);
          setYear(year1());
          return setLoading(false);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.status !== undefined
        ) {
          //  console.log("error");
          // return history.replace("/");
        }
      }
    }
    getData();
  }, []);
  //  console.log(editData);
  return (
    <>
      <style>{`
    table,td{
     border:1px solid black;
    }
  `}</style>

      <div className="w-100 mb-5">
        <ToastContainer containerId={"dx"} />
        <div className="row align-items-center mb-3">
          <div className="col-12">
            <div className="d-flex flex-row align-items-center  justify-content-start">
              <div
                className="mr-3"
                style={{ cursor: "pointer" }}
                onClick={() => props.close(false)}
              >
                <IconContext.Provider
                  value={{
                    size: "40px",
                    color: "#6a707e",
                  }}
                >
                  <MdKeyboardBackspace />
                </IconContext.Provider>
              </div>

              <h4 className="header-text " style={{ marginLeft: "20px" }}>
                Edit Slab # {props.data.sid}
              </h4>
            </div>
          </div>
          {/* <div className="col-3">
            
          </div>*/}
        </div>

        {loading ? (
          <div className="loader-position">
            <Loader
              type="TailSpin"
              color="#E37D20"
              height={80}
              width={80}
              //3 secs
            />
          </div>
        ) : !next ? (
          <div className="card-container">
            <Formik
              initialValues={{
                state: editData[0].state,
                year: editData[0].year,
                effective_date: moment(editData[0].effective_date).format(
                  "YYYY-MM-DD"
                ),
              }}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={yup.object({
                state: yup.string().required("Please select State"),
                year: yup.number().required("Please select year"),
                effective_date: yup.date().required("Please select Date"),
              })}
              onSubmit={(values) => {
                //   console.log({ values });
                setValues(values);
                return setNext(true);
              }}
            >
              {(formik) => {
                return (
                  <>
                    <div
                      className="form-group mb-3"
                      style={{ position: "relative" }}
                      id="state"
                    >
                      <label htmlFor="status" className="create-label">
                        State {required}
                      </label>
                      <Select
                        value={{
                          value: formik.values.state,
                          label: formik.values.state,
                        }}
                        onChange={(p) => {
                          formik.setFieldValue("state", p.value);
                        }}
                        options={statesOption}
                        className="admim__Addproduct--dropdown  "
                        placeholder="Select State"
                        id="status"
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            padding: "10px 10px",
                            border: formik.errors.state
                              ? "1px solid red"
                              : "1px solid #ced4da",
                          }),
                        }}
                      />

                      {formik.errors.state && (
                        <div className="tariff-error-position">
                          <p>{formik.errors.state}</p>
                        </div>
                      )}
                    </div>
                    <div
                      className="form-group mb-3 "
                      style={{ position: "relative" }}
                      id="a123"
                    >
                      <label htmlFor="year" className="create-label">
                        Year {required}
                      </label>
                      <Select
                        value={{
                          label: formik.values.year,
                          value: formik.values.year,
                        }}
                        onChange={(p) => {
                          formik.setFieldValue("year", p.value);
                        }}
                        options={yearOption}
                        className="admim__Addproduct--dropdown "
                        placeholder="Select Year"
                        id="state"
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            padding: "10px 10px",
                            border: formik.errors.year
                              ? "1px solid red"
                              : "1px solid #ced4da",
                          }),
                        }}
                      />

                      {formik.errors.year && (
                        <div className="tariff-error-position">
                          <p>{formik.errors.year}</p>
                        </div>
                      )}
                    </div>
                    <div
                      className="form-group mb-3 "
                      style={{ position: "relative" }}
                      id="a123"
                    >
                      <label htmlFor="year" className="create-label">
                        Effective_date {required}
                      </label>

                      <input
                        type="date"
                        min={`${formik.values.year}-01-01`}
                        defaultValue={
                          formik.values.year
                            ? `${formik.values.year}-01-01`
                            : ""
                        }
                        className={
                          formik.errors && formik.errors.effective_date
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        style={{ padding: " 17px 10px" }}
                        value={formik.values.effective_date}
                        onChange={formik.handleChange("effective_date")}
                        placeholder="MM-DD-YYYY"
                      />

                      {formik.errors.effective_date && (
                        <div className="tariff-error-position">
                          <p>{formik.errors.effective_date}</p>
                        </div>
                      )}
                    </div>

                    <div className="d-flex flex-row align-items-center justify-content-end mt-2">
                      <Button
                        variant="contained"
                        id="createBtn"
                        className={"createBtn"}
                        onClick={formik.handleSubmit}
                        // disabled={true}
                      >
                        Next
                      </Button>
                    </div>
                  </>
                );
              }}
            </Formik>
          </div>
        ) : (
          <Formik
            initialValues={{
              ...editData[1],
            }}
            // validateOnChange={false}
            // validateOnBlur={false}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              // console.log(values);
              try {
                const data = {
                  sid: props.data.sid,
                  formValues: values,
                  slabData,
                };
                console.log("submitDaatta", data);
                const response = await axios.post(
                  Global.base_url + "slab/edit",
                  data,
                  config2
                );
                if (response.status === 200) {
                  props.close(false);
                  return props.toaster(1, "Slab Updated");
                }
              } catch (error) {
                if (
                  (error.response && error.response.status === 401) ||
                  (error.response.status === 400 &&
                    error.response.status !== undefined)
                ) {
                  // props.close(false)
                  // toast.error("Tariff Cannot be Created", {
                  //   containerId: "dx",
                  // });
                  return props.toaster(0, "Update Failed");
                }
                // console.log("error", error);
              }
            }}
          >
            {(formik) => {
              return (
                <>
                  <div style={{ marginTop: "30px" }}>
                    <Card
                      className="shadow"
                      style={{ backgroundColor: "white" }}
                    >
                      <CardHeader className="border-0">
                        <div
                          className="row"
                          style={{
                            padding: "25px 0px",
                            backgroundColor: "#000",
                          }}
                        >
                          <div className="col-md-3">
                            <h5 style={{ color: "#fff" }}>
                              ONE PAGE STATEMENT ON
                            </h5>
                          </div>
                          <div className="col-md-4">
                            <h5 style={{ color: "#fff", textAlign: "center" }}>
                              TARIFF RATES AS IN THE {stateErc(slabData.state)}
                            </h5>
                          </div>
                          <div className="col-md-5">
                            <h5 style={{ color: "#fff" }}>
                              ORDER NO:T.P.NO.1 of {slabData.year} DT:
                              {moment(slabData.effective_date).format(
                                "DD-MM-YYYY"
                              )}
                            </h5>
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{
                            padding: "12px 0px",
                            backgroundColor: "antiquewhite",
                          }}
                        >
                          <h5
                            style={{
                              paddingBottom: "12px",
                              textAlign: "center",
                            }}
                          >
                            Revised Tariff rates with effect from{" "}
                            {moment(slabData.effective_date).format(
                              "DD-MM-YYYY"
                            )}{" "}
                            Approved rate and payable by the Consumer
                          </h5>
                          <br />
                          <h5 style={{ textAlign: "center" }}>
                            (Category of Consumers * Fully / # Partly subsidised
                            by the Government)
                          </h5>
                        </div>
                        <div
                          className="ht-accordion"
                          onClick={() => setOpen1(!open1)}
                          style={{
                            border: formik.errors
                              ? formik.errors.hightension
                                ? "1px solid red"
                                : "1px solid rgb(114, 114, 100)"
                              : "1px solid rgb(114, 114, 100)",
                          }}
                        >
                          <div className="htaccordion_container">
                            <h5>
                              I - High Tension Supply{" "}
                              {formik.errors ? (
                                formik.errors.hightension ? (
                                  <span style={{ color: "red" }}>
                                    ( All fields are required )
                                  </span>
                                ) : null
                              ) : null}
                            </h5>
                            <div>
                              <IconContext.Provider
                                value={{
                                  size: "50px",
                                  color: "rgb(114, 114, 100)",
                                }}
                              >
                                {open1 ? (
                                  <RiArrowDropUpLine />
                                ) : (
                                  <RiArrowDropDownLine />
                                )}
                              </IconContext.Provider>
                            </div>
                          </div>
                        </div>
                        <div className="table-responsive">
                          {open1 ? (
                            <table
                              className="table  align-items-center  slab-ht-table"
                              style={{ marginTop: "30px" }}
                            >
                              <thead>
                                <tr>
                                  <th
                                    colSpan={3}
                                    style={{ borderBottom: "0px" }}
                                  ></th>

                                  <th
                                    colSpan="2"
                                    style={{
                                      color: "#fff",
                                      backgroundColor: "#000",
                                      border: "1px solid",
                                      padding: "5px",
                                      textAlign: "center",
                                    }}
                                  >
                                    TARIFF FIXED BY TNERC
                                  </th>
                                  <th
                                    colspan="2"
                                    style={{
                                      color: "#fff",
                                      backgroundColor: "#000",
                                      padding: "5px",
                                      textAlign: "center",
                                    }}
                                  >
                                    TARIFF PAYABLE BY THE CONSUMER
                                  </th>
                                </tr>
                                <tr>
                                  <td style={{ textAlign: "center" }}>
                                    {" "}
                                    Tariff
                                  </td>
                                  <td
                                    style={{ textAlign: "center" }}
                                    colSpan={2}
                                  >
                                    Category of Consumers
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    Energy Charges (Rs/unit)
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    Demand Charges (Rs/kVA/month)
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    Energy Charges after Govt's subsidy
                                    (Rs/unit)
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    Demand Charges after Govt's subsidy
                                    (Rs/kVA/month)
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={{ textAlign: "center" }}>I-A</td>
                                  <td
                                    style={{ textAlign: "center" }}
                                    colSpan={2}
                                  >
                                    Industries , Registered Factories , Textlies
                                    , Tea estates , IT services , start up power
                                    provided to generators.etc.,
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT1A
                                            ? formik.errors.hightension.HT1A
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT1A
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT1A.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT1A
                                        ? formik.errors.hightension.HT1A
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT1A
                                            ? formik.errors.hightension.HT1A
                                                .demand_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT1A
                                          .demand_charges
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT1A.demand_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT1A
                                        ? formik.errors.hightension.HT1A
                                            .demand_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT1A
                                            ? formik.errors.hightension.HT1A
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT1A
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT1A.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT1A
                                        ? formik.errors.hightension.HT1A
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT1A
                                            ? formik.errors.hightension.HT1A
                                                .subsidy_demand
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT1A
                                          .subsidy_demand
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT1A.subsidy_demand"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT1A
                                        ? formik.errors.hightension.HT1A
                                            .subsidy_demand && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ textAlign: "center" }}>I-B</td>
                                  <td
                                    style={{ textAlign: "center" }}
                                    colSpan={2}
                                  >
                                    Railway Traction
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT1B
                                            ? formik.errors.hightension.HT1B
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                      textAlign: "center",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT1B
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT1B.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT1B
                                        ? formik.errors.hightension.HT1B
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT1B
                                            ? formik.errors.hightension.HT1B
                                                .demand_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT1B
                                          .demand_charges
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT1B.demand_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT1B
                                        ? formik.errors.hightension.HT1B
                                            .demand_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT1B
                                            ? formik.errors.hightension.HT1B
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT1B
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT1B.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT1B
                                        ? formik.errors.hightension.HT1B
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT1B
                                            ? formik.errors.hightension.HT1B
                                                .subsidy_demand
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT1B
                                          .subsidy_demand
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT1B.subsidy_demand"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT1B
                                        ? formik.errors.hightension.HT1B
                                            .subsidy_demand && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ textAlign: "center" }}>II-A</td>
                                  <td
                                    style={{ textAlign: "center" }}
                                    colSpan={2}
                                  >
                                    Govt. and Govt. aided Educational
                                    Institutions and hostels, Government
                                    Hospitals, Public Lighting and Water Supply
                                    , Actual places of public worship etc.,
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT2A
                                            ? formik.errors.hightension.HT2A
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT2A
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT2A.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT2A
                                        ? formik.errors.hightension.HT2A
                                            .demand_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT2A
                                            ? formik.errors.hightension.HT2A
                                                .demand_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT2A
                                          .demand_charges
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT2A.demand_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT2A
                                        ? formik.errors.hightension.HT2A
                                            .demand_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT2A
                                            ? formik.errors.hightension.HT2A
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT2A
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT2A.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT2A
                                        ? formik.errors.hightension.HT2A
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT2A
                                            ? formik.errors.hightension.HT2A
                                                .subsidy_demand
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT2A
                                          .subsidy_demand
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT2A.subsidy_demand"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT2A
                                        ? formik.errors.hightension.HT2A
                                            .subsidy_demand && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ textAlign: "center" }}>II-B</td>
                                  <td
                                    style={{ textAlign: "center" }}
                                    colSpan={2}
                                  >
                                    Private Educational Institutions & Hostels
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT2B
                                            ? formik.errors.hightension.HT2B
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT2B
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT2B.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT2B
                                        ? formik.errors.hightension.HT2B
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT2B
                                            ? formik.errors.hightension.HT2B
                                                .demand_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT2B
                                          .demand_charges
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT2B.demand_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT2B
                                        ? formik.errors.hightension.HT2B
                                            .demand_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT2B
                                            ? formik.errors.hightension.HT2B
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT2B
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT2B.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT2B
                                        ? formik.errors.hightension.HT2B
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT2B
                                            ? formik.errors.hightension.HT2B
                                                .subsidy_demand
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT2B
                                          .subsidy_demand
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT2B.subsidy_demand"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension
                                        ? formik.errors.hightension.HT2B
                                          ? formik.errors.hightension.HT2B
                                              .subsidy_demand && (
                                              <div className="error-position-slab">
                                                <span>Required</span>
                                              </div>
                                            )
                                          : null
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ textAlign: "center" }}>III</td>
                                  <td
                                    style={{ textAlign: "center" }}
                                    colSpan={2}
                                  >
                                    All other categories of consumers not
                                    covered under HT-I-A, I-B, II-A, IV and V
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT3
                                            ? formik.errors.hightension.HT3
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT3
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT3.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT3
                                        ? formik.errors.hightension.HT3
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT3
                                            ? formik.errors.hightension.HT3
                                                .demand_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT3
                                          .demand_charges
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT3.demand_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT3
                                        ? formik.errors.hightension.HT3
                                            .demand_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT3
                                            ? formik.errors.hightension.HT3
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT3
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT3.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT3
                                        ? formik.errors.hightension.HT3
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT3
                                            ? formik.errors.hightension.HT3
                                                .subsidy_demand
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT3
                                          .subsidy_demand
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT3.subsidy_demand"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT3
                                        ? formik.errors.hightension.HT3
                                            .subsidy_demand && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr style={{ color: "darkcyan" }}>
                                  <td style={{ textAlign: "center" }}>IV</td>
                                  <td
                                    style={{ textAlign: "center" }}
                                    colSpan={2}
                                  >
                                    Lift Irrigation societies for Agriculture
                                    registered under Co-op Societies or under
                                    any other Act. (Fully subsidised by the
                                    Govt.)
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT4
                                            ? formik.errors.hightension.HT4
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT4
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT4.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT4
                                        ? formik.errors.hightension.HT4
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT4
                                            ? formik.errors.hightension.HT4
                                                .demand_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",

                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT4
                                          .demand_charges
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT4.demand_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT4
                                        ? formik.errors.hightension.HT4
                                            .demand_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT4
                                            ? formik.errors.hightension.HT4
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT4
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT4.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT4
                                        ? formik.errors.hightension.HT4
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT4
                                            ? formik.errors.hightension.HT4
                                                .subsidy_demand
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT4
                                          .subsidy_demand
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT4.subsidy_demand"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT4
                                        ? formik.errors.hightension.HT4
                                            .subsidy_demand && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ textAlign: "center" }}>V</td>
                                  <td
                                    style={{ textAlign: "center" }}
                                    colSpan={2}
                                  >
                                    HT Temporary Supply for construction and
                                    other temporary purposes
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT5
                                            ? formik.errors.hightension.HT5
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT5
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT5.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT5
                                        ? formik.errors.hightension.HT5
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT5
                                            ? formik.errors.hightension.HT5
                                                .demand_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT5
                                          .demand_charges
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT5.demand_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT5
                                        ? formik.errors.hightension.HT5
                                            .demand_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT5
                                            ? formik.errors.hightension.HT5
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT5
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT5.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT5
                                        ? formik.errors.hightension.HT5
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.hightension
                                          ? formik.errors.hightension.HT5
                                            ? formik.errors.hightension.HT5
                                                .subsidy_demand
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.hightension.HT5
                                          .subsidy_demand
                                      }
                                      onChange={formik.handleChange(
                                        "hightension.HT5.subsidy_demand"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.hightension
                                      ? formik.errors.hightension.HT5
                                        ? formik.errors.hightension.HT5
                                            .subsidy_demand && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          ) : null}{" "}
                        </div>
                        <div
                          className="ht-accordion"
                          onClick={() => setOpen2(!open2)}
                          style={{
                            border: formik.errors
                              ? formik.errors.lowtension
                                ? "1px solid red"
                                : "1px solid rgb(114, 114, 100)"
                              : "1px solid rgb(114, 114, 100)",
                          }}
                        >
                          <div className="htaccordion_container">
                            <h5>
                              II - Low Tension Supply{" "}
                              {formik.errors ? (
                                formik.errors.lowtension ? (
                                  <span style={{ color: "red" }}>
                                    ( All fields are required )
                                  </span>
                                ) : null
                              ) : null}{" "}
                            </h5>

                            <div>
                              <IconContext.Provider
                                value={{
                                  size: "50px",
                                  color: "rgb(114, 114, 100)",
                                }}
                              >
                                {open2 ? (
                                  <RiArrowDropUpLine />
                                ) : (
                                  <RiArrowDropDownLine />
                                )}
                              </IconContext.Provider>
                            </div>
                          </div>
                        </div>

                        <div className="table-responsive">
                          {open2 ? (
                            <table className="table align-items-center slab-lt-table">
                              <thead>
                                <tr>
                                  <th style={{ textAlign: "center" }}>
                                    Tariff
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    Category of Consumers & slabs
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    Energy Charges (Rs/Unit){" "}
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    Fixed Charges for two months(Rs)
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    Energy Charges after Govt's subsidy
                                    (Rs/unit)
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    Fixed Charges for two months after Govt's
                                    subsidy (Rs)
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr style={{ color: "#084298" }}>
                                  <td
                                    rowSpan="16"
                                    style={{ textAlign: "center" }}
                                  >
                                    # I-A
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      padding: "10px",
                                      fontWeight: "bold",
                                    }}
                                    colspan="5"
                                  >
                                    Domestic , Handloom, Old age homes,
                                    Consulting rooms, Nutritious Meals Centres
                                    etc.
                                  </td>
                                </tr>
                                <tr style={{ color: "brown" }}>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      color: "green",
                                      padding: "8px",
                                    }}
                                    colSpan="6"
                                  >
                                    Consumption upto 100 units bi-monthly
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    color: "#f50057",
                                  }}
                                >
                                  <td style={{ textAlign: "center" }}>
                                    (100 units free scheme) 0-100 units
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Aa
                                            ? formik.errors.lowtension.LT1Aa
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Aa
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Aa.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Aa
                                        ? formik.errors.lowtension.LT1Aa
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Aa
                                            ? formik.errors.lowtension.LT1Aa
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT1Aa
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT1Aa.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /Service
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Aa
                                        ? formik.errors.lowtension.LT1Aa
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Aa
                                            ? formik.errors.lowtension.LT1Aa
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Aa
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Aa.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Aa
                                        ? formik.errors.lowtension.LT1Aa
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Aa
                                            ? formik.errors.lowtension.LT1Aa
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Aa
                                          .subsidy_fixed
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Aa.subsidy_fixed"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Aa
                                        ? formik.errors.lowtension.LT1Aa
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr style={{ color: "brown" }}>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      padding: "10px",
                                    }}
                                    colSpan="6"
                                  >
                                    Consumption above 100 units and upto 200
                                    bi-monthly
                                  </td>
                                </tr>

                                <tr
                                  style={{
                                    color: "#f50057",
                                  }}
                                >
                                  <td style={{ textAlign: "center" }}>
                                    (100 units free scheme) 0-100 units
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ab
                                            ? formik.errors.lowtension.LT1Ab
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Ab
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Ab.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ab
                                        ? formik.errors.lowtension.LT1Ab
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ab
                                            ? formik.errors.lowtension.LT1Ab
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT1Ab
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT1Ab.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /Service
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ab
                                        ? formik.errors.lowtension.LT1Ab
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ab
                                            ? formik.errors.lowtension.LT1Ab
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Ab
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Ab.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ab
                                        ? formik.errors.lowtension.LT1Ab
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ab
                                            ? formik.errors.lowtension.LT1Ab
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Ab
                                          .subsidy_fixed
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Ab.subsidy_fixed"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ab
                                        ? formik.errors.lowtension.LT1Ab
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr style={{ color: "brown" }}>
                                  <td style={{ textAlign: "center" }}>
                                    101-200 units
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ac
                                            ? formik.errors.lowtension.LT1Ac
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Ac
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Ac.energy_charges"
                                      )}
                                      className="lt-input"
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ac
                                        ? formik.errors.lowtension.LT1Ac
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ac
                                            ? formik.errors.lowtension.LT1Ac
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT1Ac
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT1Ac.fixed_charges"
                                        )}
                                        className="input-slab lt-input"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /Service
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ac
                                        ? formik.errors.lowtension.LT1Ac
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ac
                                            ? formik.errors.lowtension.LT1Ac
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Ac
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Ac.subsidy_energy"
                                      )}
                                      className="lt-input"
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ac
                                        ? formik.errors.lowtension.LT1Ac
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ac
                                            ? formik.errors.lowtension.LT1Ac
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Ac
                                          .subsidy_fixed
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Ac.subsidy_fixed"
                                      )}
                                      className="lt-input"
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ac
                                        ? formik.errors.lowtension.LT1Ac
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr style={{ color: "brown" }}>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      padding: "10px",
                                    }}
                                    colspan="5"
                                  >
                                    Consumption above 200 units and upto 500
                                    units bi-monthly{" "}
                                  </td>
                                </tr>

                                <tr
                                  style={{
                                    color: "#f50057",
                                  }}
                                >
                                  <td style={{ textAlign: "center" }}>
                                    (100 units free scheme) 0-100 units
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ad
                                            ? formik.errors.lowtension.LT1Ad
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Ad
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Ad.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ad
                                        ? formik.errors.lowtension.LT1Ad
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ad
                                            ? formik.errors.lowtension.LT1Ad
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT1Ad
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT1Ad.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /Service
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ad
                                        ? formik.errors.lowtension.LT1Ad
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ad
                                            ? formik.errors.lowtension.LT1Ad
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Ad
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Ad.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ad
                                        ? formik.errors.lowtension.LT1Ad
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ad
                                            ? formik.errors.lowtension.LT1Ad
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Ad
                                          .subsidy_fixed
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Ad.subsidy_fixed"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ad
                                        ? formik.errors.lowtension.LT1Ad
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr style={{ color: "brown" }}>
                                  <td style={{ textAlign: "center" }}>
                                    101-200 units
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ae
                                            ? formik.errors.lowtension.LT1Ae
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Ae
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Ae.energy_charges"
                                      )}
                                      className="lt-input"
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ae
                                        ? formik.errors.lowtension.LT1Ae
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ae
                                            ? formik.errors.lowtension.LT1Ae
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT1Ae
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT1Ae.fixed_charges"
                                        )}
                                        className="input-slab lt-input"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /Service
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ae
                                        ? formik.errors.lowtension.LT1Ae
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ae
                                            ? formik.errors.lowtension.LT1Ae
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Ae
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Ae.subsidy_energy"
                                      )}
                                      className="lt-input"
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ae
                                        ? formik.errors.lowtension.LT1Ae
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ae
                                            ? formik.errors.lowtension.LT1Ae
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Ae
                                          .subsidy_fixed
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Ae.subsidy_fixed"
                                      )}
                                      className="lt-input"
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ae
                                        ? formik.errors.lowtension.LT1Ae
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr style={{ color: "brown" }}>
                                  <td style={{ textAlign: "center" }}>
                                    201 to 500 units
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Af
                                            ? formik.errors.lowtension.LT1Af
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Af
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Af.energy_charges"
                                      )}
                                      className="lt-input"
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Af
                                        ? formik.errors.lowtension.LT1Af
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Af
                                            ? formik.errors.lowtension.LT1Af
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT1Af
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT1Af.fixed_charges"
                                        )}
                                        className="input-slab lt-input"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /Service
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Af
                                        ? formik.errors.lowtension.LT1Af
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Af
                                            ? formik.errors.lowtension.LT1Af
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Af
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Af.subsidy_energy"
                                      )}
                                      className="lt-input"
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Af
                                        ? formik.errors.lowtension.LT1Af
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Af
                                            ? formik.errors.lowtension.LT1Af
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Af
                                          .subsidy_fixed
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Af.subsidy_fixed"
                                      )}
                                      className="lt-input"
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Af
                                        ? formik.errors.lowtension.LT1Af
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      padding: "10px",
                                    }}
                                    colspan="5"
                                  >
                                    Consumption above 500 units bi-monthly
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    color: "#f50057",
                                    background: "#ccfcec",
                                  }}
                                >
                                  <td style={{ textAlign: "center" }}>
                                    (100 units free scheme) 0-100 units
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ag
                                            ? formik.errors.lowtension.LT1Ag
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Ag
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Ag.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ag
                                        ? formik.errors.lowtension.LT1Ag
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ag
                                            ? formik.errors.lowtension.LT1Ag
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT1Ag
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT1Ag.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /Service
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ag
                                        ? formik.errors.lowtension.LT1Ag
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ag
                                            ? formik.errors.lowtension.LT1Ag
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Ag
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Ag.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ag
                                        ? formik.errors.lowtension.LT1Ag
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ag
                                            ? formik.errors.lowtension.LT1Ag
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Ag
                                          .subsidy_fixed
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Ag.subsidy_fixed"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ag
                                        ? formik.errors.lowtension.LT1Ag
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ textAlign: "center" }}>
                                    101-200 units
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ah
                                            ? formik.errors.lowtension.LT1Ah
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Ah
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Ah.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ah
                                        ? formik.errors.lowtension.LT1Ah
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ah
                                            ? formik.errors.lowtension.LT1Ah
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT1Ah
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT1Ah.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /Service
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ah
                                        ? formik.errors.lowtension.LT1Ah
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ah
                                            ? formik.errors.lowtension.LT1Ah
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Ah
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Ah.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ah
                                        ? formik.errors.lowtension.LT1Ah
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ah
                                            ? formik.errors.lowtension.LT1Ah
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Ah
                                          .subsidy_fixed
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Ah.subsidy_fixed"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ah
                                        ? formik.errors.lowtension.LT1Ah
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ textAlign: "center" }}>
                                    201-500 units
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ai
                                            ? formik.errors.lowtension.LT1Ai
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Ai
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Ai.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ai
                                        ? formik.errors.lowtension.LT1Ai
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ai
                                            ? formik.errors.lowtension.LT1Ai
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT1Ai
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT1Ai.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /Service
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ai
                                        ? formik.errors.lowtension.LT1Ai
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ai
                                            ? formik.errors.lowtension.LT1Ai
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Ai
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Ai.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ai
                                        ? formik.errors.lowtension.LT1Ai
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ai
                                            ? formik.errors.lowtension.LT1Ai
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Ai
                                          .subsidy_fixed
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Ai.subsidy_fixed"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ai
                                        ? formik.errors.lowtension.LT1Ai
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ textAlign: "center" }}>
                                    above 500 units
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Aj
                                            ? formik.errors.lowtension.LT1Aj
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Aj
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Aj.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Aj
                                        ? formik.errors.lowtension.LT1Aj
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Aj
                                            ? formik.errors.lowtension.LT1Aj
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT1Aj
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT1Aj.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /Service
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Aj
                                        ? formik.errors.lowtension.LT1Aj
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Aj
                                            ? formik.errors.lowtension.LT1Aj
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Aj
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Aj.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Aj
                                        ? formik.errors.lowtension.LT1Aj
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Aj
                                            ? formik.errors.lowtension.LT1Aj
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Aj
                                          .subsidy_fixed
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Aj.subsidy_fixed"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Aj
                                        ? formik.errors.lowtension.LT1Aj
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    color: "darkblue",
                                    background: "#ccfcec",
                                  }}
                                >
                                  <td colspan="5" style={{ padding: "25px" }}>
                                    For Handlooms in residence, 0 to 200 units
                                    bimonthly is free. (Above 200 units
                                    bi-monthly, the corresponding slab in the
                                    domestic tariff is applicable)
                                  </td>
                                </tr>
                                <tr style={{ color: "#f50057" }}>
                                  <td
                                    style={{ textAlign: "center" }}
                                    rowspan="2"
                                  >
                                    *I-B
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    Huts in village panchayats,TAHDCO:- Till
                                    installation of meters (Fully subsidised by
                                    the Govt.)
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ba
                                            ? formik.errors.lowtension.LT1Ba
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Ba
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Ba.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ba
                                        ? formik.errors.lowtension.LT1Ba
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ba
                                            ? formik.errors.lowtension.LT1Ba
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT1Ba
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT1Ba.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /Service
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ba
                                        ? formik.errors.lowtension.LT1Ba
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ba
                                            ? formik.errors.lowtension.LT1Ba
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Ba
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Ba.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ba
                                        ? formik.errors.lowtension.LT1Ba
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Ba
                                            ? formik.errors.lowtension.LT1Ba
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Ba
                                          .subsidy_fixed
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Ba.subsidy_fixed"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Ba
                                        ? formik.errors.lowtension.LT1Ba
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr style={{ color: "#f50057" }}>
                                  <td style={{ textAlign: "center" }}>
                                    On installation of meters (Fully subsided by
                                    the Govt.)
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Bb
                                            ? formik.errors.lowtension.LT1Bb
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Bb
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Bb.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Bb
                                        ? formik.errors.lowtension.LT1Bb
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Bb
                                            ? formik.errors.lowtension.LT1Bb
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT1Bb
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT1Bb.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /Service
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Bb
                                        ? formik.errors.lowtension.LT1Bb
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Bb
                                            ? formik.errors.lowtension.LT1Bb
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Bb
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Bb.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Bb
                                        ? formik.errors.lowtension.LT1Bb
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1Bb
                                            ? formik.errors.lowtension.LT1Bb
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT1Bb
                                          .subsidy_fixed
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT1Bb.subsidy_fixed"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1Bb
                                        ? formik.errors.lowtension.LT1Bb
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>

                                <tr>
                                  <td style={{ textAlign: "center" }}>I-C</td>
                                  <td style={{ textAlign: "center" }}>
                                    L.T. Bulk supply to residential Colonies of
                                    Railway, Defence , Police quarters etc.
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1C
                                            ? formik.errors.lowtension.LT1C
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",

                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        className="input-slab-bg"
                                        value={
                                          formik.values.lowtension.LT1C
                                            .energy_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT1C.energy_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          color: "rgb(245, 0, 87)",
                                          border: "none",
                                          outline: "none",
                                        }}
                                      />
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1C
                                        ? formik.errors.lowtension.LT1C
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1C
                                            ? formik.errors.lowtension.LT1C
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT1C
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT1C.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /Service
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1C
                                        ? formik.errors.lowtension.LT1C
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>

                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1C
                                            ? formik.errors.lowtension.LT1C
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        className="input-slab-bg"
                                        value={
                                          formik.values.lowtension.LT1C
                                            .subsidy_energy
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT1C.subsidy_energy"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          color: "rgb(245, 0, 87)",
                                          border: "none",
                                          outline: "none",
                                        }}
                                      />
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1C
                                        ? formik.errors.lowtension.LT1C
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT1C
                                            ? formik.errors.lowtension.LT1C
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT1C
                                            .subsidy_fixed
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT1C.subsidy_fixed"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /Service
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT1C
                                        ? formik.errors.lowtension.LT1C
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ textAlign: "center" }}>II-A</td>
                                  <td style={{ textAlign: "center" }}>
                                    Public lighting by Govt./Local bodies,
                                    Public water supply, Sewerage etc.,
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT2A
                                            ? formik.errors.lowtension.LT2A
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        className="input-slab-bg"
                                        value={
                                          formik.values.lowtension.LT2A
                                            .energy_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT2A.energy_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          color: "rgb(245, 0, 87)",
                                          border: "none",
                                          outline: "none",
                                        }}
                                      />
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT2A
                                        ? formik.errors.lowtension.LT2A
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT2A
                                            ? formik.errors.lowtension.LT2A
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT2A
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT2A.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT2A
                                        ? formik.errors.lowtension.LT2A
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>

                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT2A
                                            ? formik.errors.lowtension.LT2A
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        className="input-slab-bg"
                                        value={
                                          formik.values.lowtension.LT2A
                                            .subsidy_energy
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT2A.subsidy_energy"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          color: "rgb(245, 0, 87)",
                                          border: "none",
                                          outline: "none",
                                        }}
                                      />
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT2A
                                        ? formik.errors.lowtension.LT2A
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT2A
                                            ? formik.errors.lowtension.LT2A
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT2A
                                            .subsidy_fixed
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT2A.subsidy_fixed"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT2A
                                        ? formik.errors.lowtension.LT2A
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ textAlign: "center" }}>
                                    II-B(1)
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    Govt and Govt. aided Educational
                                    Institutions, Govt. Hospitals and Research
                                    labs, etc
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT2Ba
                                            ? formik.errors.lowtension.LT2Ba
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        className="input-slab-bg"
                                        value={
                                          formik.values.lowtension.LT2Ba
                                            .energy_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT2Ba.energy_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          color: "rgb(245, 0, 87)",
                                          border: "none",
                                          outline: "none",
                                        }}
                                      />
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT2Ba
                                        ? formik.errors.lowtension.LT2Ba
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT2Ba
                                            ? formik.errors.lowtension.LT2Ba
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT2Ba
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT2Ba.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT2Ba
                                        ? formik.errors.lowtension.LT2Ba
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>

                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT2Ba
                                            ? formik.errors.lowtension.LT2Ba
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        className="input-slab-bg"
                                        value={
                                          formik.values.lowtension.LT2Ba
                                            .subsidy_energy
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT2Ba.subsidy_energy"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          color: "rgb(245, 0, 87)",
                                          border: "none",
                                          outline: "none",
                                        }}
                                      />
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT2Ba
                                        ? formik.errors.lowtension.LT2Ba
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT2Ba
                                            ? formik.errors.lowtension.LT2Ba
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT2Ba
                                            .subsidy_fixed
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT2Ba.subsidy_fixed"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT2Ba
                                        ? formik.errors.lowtension.LT2Ba
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ textAlign: "center" }}>
                                    II-B(2)
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    Private Educational Institutions & Hostels
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT2Bb
                                            ? formik.errors.lowtension.LT2Bb
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        className="input-slab-bg"
                                        value={
                                          formik.values.lowtension.LT2Bb
                                            .energy_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT2Bb.energy_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          color: "rgb(245, 0, 87)",
                                          border: "none",
                                          outline: "none",
                                        }}
                                      />
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT2Bb
                                        ? formik.errors.lowtension.LT2Bb
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT2Bb
                                            ? formik.errors.lowtension.LT2Bb
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT2Bb
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT2Bb.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT2Bb
                                        ? formik.errors.lowtension.LT2Bb
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>

                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT2Bb
                                            ? formik.errors.lowtension.LT2Bb
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        className="input-slab-bg"
                                        value={
                                          formik.values.lowtension.LT2Bb
                                            .subsidy_energy
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT2Bb.subsidy_energy"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          color: "rgb(245, 0, 87)",
                                          border: "none",
                                          outline: "none",
                                        }}
                                      />
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT2Bb
                                        ? formik.errors.lowtension.LT2Bb
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT2Bb
                                            ? formik.errors.lowtension.LT2Bb
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT2Bb
                                            .subsidy_fixed
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT2Bb.subsidy_fixed"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT2Bb
                                        ? formik.errors.lowtension.LT2Bb
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>

                                <tr style={{ color: "brown" }}>
                                  <td
                                    style={{ textAlign: "center" }}
                                    rowspan="2"
                                  >
                                    # II-C
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    Actual Places of Public worship(Bi-monthly)
                                    0-120 units{" "}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT2Ca
                                            ? formik.errors.lowtension.LT2Ca
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT2Ca
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT2Ca.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT2Ca
                                        ? formik.errors.lowtension.LT2Ca
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT2Ca
                                            ? formik.errors.lowtension.LT2Ca
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT2Ca
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT2Ca.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          color: "rgb(245, 0, 87)",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT2Ca
                                        ? formik.errors.lowtension.LT2Ca
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>

                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT2Ca
                                            ? formik.errors.lowtension.LT2Ca
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT2Ca
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT2Ca.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT2Ca
                                        ? formik.errors.lowtension.LT2Ca
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT2Ca
                                            ? formik.errors.lowtension.LT2Ca
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT2Ca
                                            .subsidy_fixed
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT2Ca.subsidy_fixed"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          color: "rgb(245, 0, 87)",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT2Ca
                                        ? formik.errors.lowtension.LT2Ca
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ textAlign: "center" }}>
                                    Above 120 units
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT2Cb
                                            ? formik.errors.lowtension.LT2Cb
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT2Cb
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT2Cb.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT2Cb
                                        ? formik.errors.lowtension.LT2Cb
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT2Cb
                                            ? formik.errors.lowtension.LT2Cb
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT2Cb
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT2Cb.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          color: "rgb(245, 0, 87)",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT2Cb
                                        ? formik.errors.lowtension.LT2Cb
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>

                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT2Cb
                                            ? formik.errors.lowtension.LT2Cb
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT2Cb
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT2Cb.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT2Cb
                                        ? formik.errors.lowtension.LT2Cb
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT2Cb
                                            ? formik.errors.lowtension.LT2Cb
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT2Cb
                                            .subsidy_fixed
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT2Cb.subsidy_fixed"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          color: "rgb(245, 0, 87)",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT2Cb
                                        ? formik.errors.lowtension.LT2Cb
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{ textAlign: "center" }}
                                    rowspan="3"
                                  >
                                    III-A(1)
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      padding: "25px",
                                    }}
                                    colspan="5"
                                  >
                                    Cottage and Tiny Industries, Agricultural
                                    and allied activities, Sericulture,
                                    Floriculture, Horticulture and Fish/Prawn
                                    culture etc. (contracted load shall not
                                    exceed 12 KW) (Bi-monthly)
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ textAlign: "center" }}>
                                    upto 500 units
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A1a
                                            ? formik.errors.lowtension.LT3A1a
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT3A1a
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT3A1a.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A1a
                                        ? formik.errors.lowtension.LT3A1a
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A1a
                                            ? formik.errors.lowtension.LT3A1a
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT3A1a
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT3A1a.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          color: "rgb(245, 0, 87)",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A1a
                                        ? formik.errors.lowtension.LT3A1a
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>

                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A1a
                                            ? formik.errors.lowtension.LT3A1a
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT3A1a
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT3A1a.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A1a
                                        ? formik.errors.lowtension.LT3A1a
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A1a
                                            ? formik.errors.lowtension.LT3A1a
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT3A1a
                                            .subsidy_fixed
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT3A1a.subsidy_fixed"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          color: "rgb(245, 0, 87)",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A1a
                                        ? formik.errors.lowtension.LT3A1a
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ textAlign: "center" }}>
                                    above 500 units
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A1b
                                            ? formik.errors.lowtension.LT3A1b
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT3A1b
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT3A1b.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A1b
                                        ? formik.errors.lowtension.LT3A1b
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A1b
                                            ? formik.errors.lowtension.LT3A1b
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT3A1b
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT3A1b.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          color: "rgb(245, 0, 87)",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A1b
                                        ? formik.errors.lowtension.LT3A1b
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>

                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A1b
                                            ? formik.errors.lowtension.LT3A1b
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT3A1b
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT3A1b.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A1b
                                        ? formik.errors.lowtension.LT3A1b
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A1b
                                            ? formik.errors.lowtension.LT3A1b
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT3A1b
                                            .subsidy_fixed
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT3A1b.subsidy_fixed"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          color: "rgb(245, 0, 87)",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A1b
                                        ? formik.errors.lowtension.LT3A1b
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr style={{ color: "brown" }}>
                                  <td
                                    style={{ textAlign: "center" }}
                                    rowspan="6"
                                  >
                                    # III-A(2)
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      padding: "20px",
                                    }}
                                    colspan="5"
                                  >
                                    Power Looms (contracted load shall not
                                    exceed 12 KW) incl. Winding etc.(Bi-monthly)
                                  </td>
                                </tr>
                                <tr style={{ color: "#f50057" }}>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      background: "#ccfcec",
                                    }}
                                  >
                                    (750 units bimonthly is free) upto 500 units
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A2a
                                            ? formik.errors.lowtension.LT3A2a
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT3A2a
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT3A2a.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A2a
                                        ? formik.errors.lowtension.LT3A2a
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A2a
                                            ? formik.errors.lowtension.LT3A2a
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT3A2a
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT3A2a.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          color: "rgb(245, 0, 87)",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A2a
                                        ? formik.errors.lowtension.LT3A2a
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A2a
                                            ? formik.errors.lowtension.LT3A2a
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT3A2a
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT3A2a.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A2a
                                        ? formik.errors.lowtension.LT3A2a
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A2a
                                            ? formik.errors.lowtension.LT3A2a
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT3A2a
                                          .subsidy_fixed
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT3A2a.subsidy_fixed"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A2a
                                        ? formik.errors.lowtension.LT3A2a
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>

                                <tr
                                  style={{
                                    color: "#f50057",
                                  }}
                                >
                                  <td style={{ textAlign: "center" }}>
                                    501-750 units
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A2b
                                            ? formik.errors.lowtension.LT3A2b
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT3A2b
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT3A2b.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A2b
                                        ? formik.errors.lowtension.LT3A2b
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A2b
                                            ? formik.errors.lowtension.LT3A2b
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT3A2b
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT3A2b.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          color: "rgb(245, 0, 87)",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A2b
                                        ? formik.errors.lowtension.LT3A2b
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A2b
                                            ? formik.errors.lowtension.LT3A2b
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT3A2b
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT3A2b.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A2b
                                        ? formik.errors.lowtension.LT3A2b
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A2b
                                            ? formik.errors.lowtension.LT3A2b
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT3A2b
                                          .subsidy_fixed
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT3A2b.subsidy_fixed"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A2b
                                        ? formik.errors.lowtension.LT3A2b
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr style={{ color: "brown" }}>
                                  <td style={{ textAlign: "center" }}>
                                    751-1000 units
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A2c
                                            ? formik.errors.lowtension.LT3A2c
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT3A2c
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT3A2c.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A2c
                                        ? formik.errors.lowtension.LT3A2c
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A2c
                                            ? formik.errors.lowtension.LT3A2c
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT3A2c
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT3A2c.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          color: "rgb(245, 0, 87)",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A2c
                                        ? formik.errors.lowtension.LT3A2c
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A2c
                                            ? formik.errors.lowtension.LT3A2c
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT3A2c
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT3A2c.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A2c
                                        ? formik.errors.lowtension.LT3A2c
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A2c
                                            ? formik.errors.lowtension.LT3A2c
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT3A2c
                                          .subsidy_fixed
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT3A2c.subsidy_fixed"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A2c
                                        ? formik.errors.lowtension.LT3A2c
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr style={{ color: "brown" }}>
                                  <td style={{ textAlign: "center" }}>
                                    1001-1500 units
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A2d
                                            ? formik.errors.lowtension.LT3A2d
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT3A2d
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT3A2d.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A2d
                                        ? formik.errors.lowtension.LT3A2d
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A2d
                                            ? formik.errors.lowtension.LT3A2d
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT3A2d
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT3A2d.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          color: "rgb(245, 0, 87)",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A2d
                                        ? formik.errors.lowtension.LT3A2d
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A2d
                                            ? formik.errors.lowtension.LT3A2d
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT3A2d
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT3A2d.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A2d
                                        ? formik.errors.lowtension.LT3A2d
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A2d
                                            ? formik.errors.lowtension.LT3A2d
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT3A2d
                                          .subsidy_fixed
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT3A2d.subsidy_fixed"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A2d
                                        ? formik.errors.lowtension.LT3A2d
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr style={{ color: "brown" }}>
                                  <td style={{ textAlign: "center" }}>
                                    above 1500 units
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A2e
                                            ? formik.errors.lowtension.LT3A2e
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT3A2e
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT3A2e.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A2e
                                        ? formik.errors.lowtension.LT3A2e
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A2e
                                            ? formik.errors.lowtension.LT3A2e
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT3A2e
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT3A2e.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          color: "rgb(245, 0, 87)",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A2e
                                        ? formik.errors.lowtension.LT3A2e
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A2e
                                            ? formik.errors.lowtension.LT3A2e
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT3A2e
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT3A2e.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A2e
                                        ? formik.errors.lowtension.LT3A2e
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3A2e
                                            ? formik.errors.lowtension.LT3A2e
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT3A2e
                                          .subsidy_fixed
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT3A2e.subsidy_fixed"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3A2e
                                        ? formik.errors.lowtension.LT3A2e
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ textAlign: "center" }}>III-B</td>
                                  <td style={{ textAlign: "center" }}>
                                    Industries(Not covered under LT-III-A(1) &
                                    III-A(2) ) , If the connected load of  all
                                    industries in LT-III-A(1) &
                                    III-A(2)connected load exceeds 12 KW,
                                    welding sets and IT
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3B
                                            ? formik.errors.lowtension.LT3B
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                      textAlign: "center",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT3B
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT3B.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3B
                                        ? formik.errors.lowtension.LT3B
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3B
                                            ? formik.errors.lowtension.LT3B
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT3B
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT3B.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3B
                                        ? formik.errors.lowtension.LT3B
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>

                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3B
                                            ? formik.errors.lowtension.LT3B
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT3B
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT3B.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3B
                                        ? formik.errors.lowtension.LT3B
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>

                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT3B
                                            ? formik.errors.lowtension.LT3B
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT3B
                                            .subsidy_fixed
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT3B.subsidy_fixed"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT3B
                                        ? formik.errors.lowtension.LT3B
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr style={{ color: "#f50057" }}>
                                  <td
                                    style={{ textAlign: "center" }}
                                    rowspan="2"
                                  >
                                    * IV
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    Agricultural, sericulture, floriculture,
                                    horticulture and fish/prawn culture etc., -
                                    Till installation of meters (Fully
                                    subsidised by the Govt.)
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT4A
                                            ? formik.errors.lowtension.LT4A
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT4A
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT4A.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT4A
                                        ? formik.errors.lowtension.LT4A
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT4A
                                            ? formik.errors.lowtension.LT4A
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT4A
                                          .fixed_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT4A.fixed_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT4A
                                        ? formik.errors.lowtension.LT4A
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT4A
                                            ? formik.errors.lowtension.LT4A
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT4A
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT4A.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT4A
                                        ? formik.errors.lowtension.LT4A
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT4A
                                            ? formik.errors.lowtension.LT4A
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT4A
                                          .subsidy_fixed
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT4A.subsidy_fixed"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT4A
                                        ? formik.errors.lowtension.LT4A
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr style={{ color: "#f50057" }}>
                                  <td style={{ textAlign: "center" }}>
                                    Agricultural, sericulture, floriculture,
                                    horticulture and fish/prawn culture etc., On
                                    installation of meters (Fully subsidised by
                                    the Govt.)
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT4B
                                            ? formik.errors.lowtension.LT4B
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT4B
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT4B.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT4B
                                        ? formik.errors.lowtension.LT4B
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT4B
                                            ? formik.errors.lowtension.LT4B
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT4B
                                          .fixed_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT4B.fixed_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT4B
                                        ? formik.errors.lowtension.LT4B
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT4B
                                            ? formik.errors.lowtension.LT4B
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT4B
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT4B.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT4B
                                        ? formik.errors.lowtension.LT4B
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT4B
                                            ? formik.errors.lowtension.LT4B
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT4B
                                          .subsidy_fixed
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT4B.subsidy_fixed"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT4B
                                        ? formik.errors.lowtension.LT4B
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{ textAlign: "center" }}
                                    rowspan="3"
                                  >
                                    V
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      padding: "10px",
                                    }}
                                    colspan="5"
                                  >
                                    Commercial (Not covered under LT-I-A, I-B,
                                    I-C, II-A, II-B(1), II-B(2), II-C, III-A(1),
                                    III-A(2), III-B, IV and VI)
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ textAlign: "center" }}>
                                    consumption upto 100 units bi-monthly 0-100
                                    units
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT5A
                                            ? formik.errors.lowtension.LT5A
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT5A
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT5A.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT5A
                                        ? formik.errors.lowtension.LT5A
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT5A
                                            ? formik.errors.lowtension.LT5A
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT5A
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT5A.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT5A
                                        ? formik.errors.lowtension.LT5A
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT5A
                                            ? formik.errors.lowtension.LT5A
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT5A
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT5A.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT5A
                                        ? formik.errors.lowtension.LT5A
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT5A
                                            ? formik.errors.lowtension.LT5A
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT5A
                                            .subsidy_fixed
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT5A.subsidy_fixed"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT5A
                                        ? formik.errors.lowtension.LT5A
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ textAlign: "center" }}>
                                    consumption above 100 units bi-monthly (for
                                    all units)
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT5B
                                            ? formik.errors.lowtension.LT5B
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT5B
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT5B.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT5B
                                        ? formik.errors.lowtension.LT5B
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT5B
                                            ? formik.errors.lowtension.LT5B
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT5B
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT5B.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT5B
                                        ? formik.errors.lowtension.LT5B
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT5B
                                            ? formik.errors.lowtension.LT5B
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT5B
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT5B.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT5B
                                        ? formik.errors.lowtension.LT5B
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT5B
                                            ? formik.errors.lowtension.LT5B
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT5B
                                            .subsidy_fixed
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT5B.subsidy_fixed"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT5B
                                        ? formik.errors.lowtension.LT5B
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    style={{ textAlign: "center" }}
                                    rowspan="2"
                                  >
                                    VI
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    For temporary activities, construction of
                                    buildings and Lavish illumination,
                                    additional construction of beyond 2000
                                    square feet in the premises of an existing
                                    consumer.
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT6A
                                            ? formik.errors.lowtension.LT6A
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT6A
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT6A.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT6A
                                        ? formik.errors.lowtension.LT6A
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT6A
                                            ? formik.errors.lowtension.LT6A
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT6A
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT6A.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT6A
                                        ? formik.errors.lowtension.LT6A
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT6A
                                            ? formik.errors.lowtension.LT6A
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT6A
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT6A.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT6A
                                        ? formik.errors.lowtension.LT6A
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT6A
                                            ? formik.errors.lowtension.LT6A
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT6A
                                            .subsidy_fixed
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT6A.subsidy_fixed"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT6A
                                        ? formik.errors.lowtension.LT6A
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>

                                <tr>
                                  <td style={{ textAlign: "center" }}>
                                    Lavish illuminations
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT6B
                                            ? formik.errors.lowtension.LT6B
                                                .energy_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT6B
                                          .energy_charges
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT6B.energy_charges"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT6B
                                        ? formik.errors.lowtension.LT6B
                                            .energy_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT6B
                                            ? formik.errors.lowtension.LT6B
                                                .fixed_charges
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT6B
                                            .fixed_charges
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT6B.fixed_charges"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT6B
                                        ? formik.errors.lowtension.LT6B
                                            .fixed_charges && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT6B
                                            ? formik.errors.lowtension.LT6B
                                                .subsidy_energy
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      value={
                                        formik.values.lowtension.LT6B
                                          .subsidy_energy
                                      }
                                      onChange={formik.handleChange(
                                        "lowtension.LT6B.subsidy_energy"
                                      )}
                                      style={{
                                        textAlign: "center",
                                        color: "rgb(245, 0, 87)",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT6B
                                        ? formik.errors.lowtension.LT6B
                                            .subsidy_energy && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                  <td
                                    className="input-pink"
                                    style={{
                                      border:
                                        formik.errors &&
                                        formik.errors.lowtension
                                          ? formik.errors.lowtension.LT6B
                                            ? formik.errors.lowtension.LT6B
                                                .subsidy_fixed
                                              ? "1px solid red"
                                              : "1px solid black"
                                            : "1px solid black"
                                          : "1px solid black",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="input-content-slab">
                                      <input
                                        type="number"
                                        value={
                                          formik.values.lowtension.LT6B
                                            .subsidy_fixed
                                        }
                                        onChange={formik.handleChange(
                                          "lowtension.LT6B.subsidy_fixed"
                                        )}
                                        style={{
                                          textAlign: "center",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        className="input-slab-sp input-slab-bg"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "40%",
                                          paddingTop: "15px",
                                        }}
                                      >
                                        /KW
                                      </p>
                                    </div>
                                    {formik.errors && formik.errors.lowtension
                                      ? formik.errors.lowtension.LT6B
                                        ? formik.errors.lowtension.LT6B
                                            .subsidy_fixed && (
                                            <div className="error-position-slab">
                                              <span>Required</span>
                                            </div>
                                          )
                                        : null
                                      : null}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          ) : null}{" "}
                        </div>
                        <div className="d-flex flex-row align-items-center justify-content-center mt-4 mb-4">
                          <Button
                            variant="contained"
                            id="createSlabBtn"
                            className={"createSlabBtn"}
                            onClick={formik.handleSubmit}
                            // disabled={true}
                          >
                            Update
                          </Button>
                        </div>
                      </CardHeader>
                    </Card>
                  </div>
                </>
              );
            }}
          </Formik>
        )}
      </div>
    </>
  );
};

export default NewSlab;
