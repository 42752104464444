import React from "react";
import { Doughnut } from "react-chartjs-2";
import datalables from "chartjs-plugin-datalabels";
const data = {
  labels: ["Generation", "Power Purchase", "Network Cost", "Line Losses"],
  datasets: [
    {
      data: [1.13, 2.65, 1.26, 2.81],
      backgroundColor: ["#e34850", "#ffa600", "#b79900", "#bc5090"],
    },
  ],
};

const options = {
  maintainAspectRatio: false,

  plugins: {
    title: {
      display: true,
      text: "What makes your electricity Price?",
    },
    legend: {
      position: "right",
    },
    datalabels: {
      enabled: true,
      anchor: "center",
      align: "center",
      color: "white",
      font: {
        weight: "bold",
        size: 13,
      },
    },
  },
};

const DoughnutChart = (props) => (
  <>
    <Doughnut
      plugins={[datalables]}
      data={props.data}
      options={{
        maintainAspectRatio: false,

        plugins: {
          title: {
            display: true,
            text: props.title,
            font: {
              size: 18,
            },
          },
          subtitle: {
            display: props.subtitle ? true : false,
            text: props.subtitle,
            position: "bottom",
            color: "green",
            align: "center",
            padding: {
              top: 10,
              bottom: 10,
            },
            font: {
              size: 18,
            },
          },
          legend: {
            position: props.position,
          },
          datalabels: {
            enabled: true,
            anchor: "center",
            align: "center",
            color: "white",
            font: {
              weight: "bold",
              size: 13,
            },
          },
        },
      }}
      height={props.height}
      width={props.width}
    />
  </>
);

export default DoughnutChart;
