import { React, useState, useEffect } from "react";

import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

import "../assets/css/layout.css";

import { FaGem, FaHeart } from "react-icons/fa";
import {
  RiDashboardLine,
  RiLayoutGridFill,
  RiToolsFill,
  RiCalculatorFill,
} from "react-icons/ri";
import { HiOutlineDocumentReport, HiMenu } from "react-icons/hi";
import { SiGoogleanalytics } from "react-icons/si";
import { FiSettings, FiLogOut } from "react-icons/fi";
import { IconContext } from "react-icons";
import Dashboard from "./Dashboard";
import Tariff from "./Tariff";
import Slab from "./Slab";
import Transaction from "./Transaction";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";
import SlabRates from "./SlabRates";

const Layout = (props) => {
  const [active, setActive] = useState(0);
  const [collapsed, setCollapsed] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("tabIndex")) {
      setActive(parseInt(localStorage.getItem("tabIndex")));
    }
  }, []);

  // console.log({ active });
  return (
    <div className="container-fluid clear-pm">
      <div className="d-flex  flex-row">
        <div className={collapsed ? "sidebar-collapsed" : "sidebar"}>
          <div className="sidebar-header">
            <div className="d-flex flex-row align-items-center">
              <h1
                className="sidebar-header-text"
                style={{ display: collapsed ? "none" : "block" }}
              >
                CAG
              </h1>
            </div>
            <div
              onClick={() => setCollapsed(!collapsed)}
              className="sidebar-header-Icon"
            >
              <IconContext.Provider
                value={{
                  size: collapsed ? "50px" : "30px",
                  color: "white",
                  // className: "header__icons",
                }}
              >
                <RiToolsFill />
              </IconContext.Provider>
            </div>
          </div>

          <div className="sidebar-items">
            <div className="sidebar-section">
              <p
                className="section-header-text "
                style={{ display: collapsed ? "none" : "block" }}
              >
                Navigations
              </p>
              <div
                className={active === 0 ? "item-active" : "item"}
                key={1}
                onClick={() => {
                  localStorage.setItem("tabIndex", 0);
                  setActive(0);
                }}
              >
                <IconContext.Provider
                  value={{
                    size: collapsed ? "100px" : "30px",
                    className: "sidebar-icon",
                  }}
                >
                  <RiLayoutGridFill />
                </IconContext.Provider>
                <span
                  className="sidebar-item--text"
                  style={{ display: collapsed ? "none" : "block" }}
                >
                  Dashboard
                </span>
              </div>
              <div
                className={active === 3 ? "item-active" : "item"}
                key={3}
                onClick={() => {
                  localStorage.setItem("tabIndex", 3);
                  setActive(3);
                }}
              >
                <IconContext.Provider
                  value={{
                    size: collapsed ? "100px" : "30px",
                    className: "sidebar-icon",
                  }}
                >
                  <RiCalculatorFill />
                </IconContext.Provider>
                <span
                  className="sidebar-item--text"
                  style={{ display: collapsed ? "none" : "block" }}
                >
                  Transaction
                </span>
              </div>
            </div>
            <div className="sidebar-section">
              <p
                className="section-header-text "
                style={{ display: collapsed ? "none" : "block" }}
              >
                Forms
              </p>
              <div
                className={active === 1 ? "item-active" : "item"}
                key={2}
                onClick={() => {
                  localStorage.setItem("tabIndex", 1);
                  setActive(1);
                }}
              >
                <SiGoogleanalytics className="sidebar-icon" />
                <span
                  className="sidebar-item--text"
                  style={{ display: collapsed ? "none" : "block" }}
                >
                  Tariff Analysis
                </span>
              </div>
              <div
                className={active === 2 ? "item-active" : "item"}
                key={3}
                onClick={() => {
                  localStorage.setItem("tabIndex", 2);
                  setActive(2);
                }}
              >
                <HiOutlineDocumentReport className="sidebar-icon" />
                <span
                  className="sidebar-item--text"
                  style={{ display: collapsed ? "none" : "block" }}
                >
                  Slab Rates
                </span>
              </div>
            </div>
            <div className="sidebar-section" style={{ borderBottom: "0px" }}>
              <p
                className="section-header-text "
                style={{ display: collapsed ? "none" : "block" }}
              >
                Settings
              </p>
              <div
                className={"item"}
                key={4}
                onClick={() => {
                  localStorage.removeItem("tabIndex");
                  localStorage.removeItem("cag_key");
                  window.location.href = "/";
                  return;
                }}
              >
                <FiLogOut className="sidebar-icon" />
                <span
                  className="sidebar-item--text"
                  style={{ display: collapsed ? "none" : "block" }}
                >
                  Logout
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column  w-100">
          <div className="nav-header">
            <div className="d-flex flex-row align-items-center">
              <div className="image-container">
                <img
                  src="https://toppng.com/uploads/preview/file-svg-profile-icon-vector-11562942678pprjdh47a8.png"
                  style={{ width: "50px", height: "50px" }}
                />
              </div>
              <IconContext.Provider
                value={{
                  size: "30px",
                  color: "black",
                  // className: "header__icons",
                }}
              >
                <HiMenu />
              </IconContext.Provider>
            </div>
          </div>
          <div
            className="content-area"
            style={{ marginLeft: collapsed ? "100px" : "320px" }}
          >
            {active === 0 && <Dashboard />}
            {active === 1 && <Tariff />}
            {active === 2 && <Slab />}
            {active === 3 && <Transaction />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
