import React, { useState, useEffect } from "react";

import "../assets/css/dashboard.css";
import { Button } from "@material-ui/core";
import { BsThreeDotsVertical, BsPlusCircleFill } from "react-icons/bs";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaWindowClose } from "react-icons/fa";
import { MdKeyboardBackspace } from "react-icons/md";
import { IconContext } from "react-icons";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import Global from "../global";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Collapse,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { states1, year1, stateErc, stateDist } from "../global";

const validationSchema = yup.object().shape({
  tariff_issued: yup.date().required(),
  petition_filed: yup.date().required(),
  avg_cost: yup.object({
    eng_req_state: yup.number().required(),
    eng_sales: yup.number().required(),
    // TD_loss: yup.number().required(),
    power_sale_revenue: yup.number().required(),
    tot_ARR: yup.number().required(),
    ACOS: yup.number().required(),
  }),
  gen_cost1: yup.object({
    quanum_tpg: yup.number().required(),
    fc_tpg: yup.number().required(),
    eng_chrg_tpg: yup.number().required(),
    // tot_cost_tpg: yup.number().required(),
    // unit_cost_tpg: yup.number().required(),
    quanum_gtp: yup.number().required(),
    fc_gtp: yup.number().required(),
    eng_chrg_gtp: yup.number().required(),
    // tot_cost_gtp: yup.number().required(),
    // unit_cost_gtp: yup.number().required(),
  }),
  gen_cost2: yup.object({
    quanum_hpp: yup.number().required(),
    fc_hpp: yup.number().required(),
    eng_chrg_hpp: yup.number().required(),
    // tot_cost_hpp: yup.number().required(),
    // unit_cost_hpp: yup.number().required(),
    quanum_wind: yup.number().required(),
    fc_wind: yup.number().required(),
    eng_chrg_wind: yup.number().required(),
    // tot_cost_wind: yup.number().required(),
    // unit_cost_wind: yup.number().required(),
  }),
  pur_cost1: yup.object({
    quanum_wind_pur: yup.number().required(),
    totcost_wind_pur: yup.number().required(),
    // unitcost_wind_pur: yup.number().required(),
    quanum_solar_pur: yup.number().required(),
    totcost_solar_pur: yup.number().required(),
    // unitcost_solar_pur: yup.number().required(),

    quanum_other_pur: yup.number().required(),
    totcost_other_pur: yup.number().required(),
    // unitcost_other_pur: yup.number().required(),
  }),
  pur_cost2: yup.object({
    quanum_pur_cgs: yup.number().required(),
    fc_pur_cgs: yup.number().required(),
    engchrg_pur_cgs: yup.number().required(),
    // totcost_pur_cgs: yup.number().required(),
    // unitcost_pur_cgs: yup.number().required(),

    quanum_pur_ipp: yup.number().required(),
    fc_pur_ipp: yup.number().required(),
    engchrg_pur_ipp: yup.number().required(),
    // totcost_pur_ipp: yup.number().required(),
    // unitcost_pur_ipp: yup.number().required(),

    quanum_pur_open_acss: yup.number().required(),
    fc_pur_open_acss: yup.number().required(),
    engchrg_pur_open_acss: yup.number().required(),
    // totcost_pur_open_acss: yup.number().required(),
    // unitcost_pur_open_acss: yup.number().required(),
  }),
  trans_cost: yup.object({
    tn_trans_chrg: yup.number().required(),
    pgcil_chrgs: yup.number().required(),
    state_des_chrgs: yup.number().required(),
    // tot_trans_cost: yup.number().required(),
  }),
  // lineloss_cost: yup.object({
  //   // tot_pur_cost: yup.number().required(),
  //   // unit_pur_cost: yup.number().required(),
  //   // unit_pur_cost_sold: yup.number().required(),
  //   // unit_cost_lineloss: yup.number().required(),
  // }),
  distr_cost1: yup.object({
    opr_main_exp: yup.number().required(),
    deprication: yup.number().required(),
    int_fin_chrgs: yup.number().required(),
    int_capital: yup.number().required(),
    other_depts: yup.number().required(),

    other_int_fc: yup.number().required(),
    employee_cost: yup.number().required(),
    // gross_revenue_dist: yup.number().required(),
  }),
  distr_cost2: yup.object({
    less_income: yup.number().required(),
    less_nontariff_inc: yup.number().required(),
    // less_total_inc: yup.number().required(),
    // net_distr_cost: yup.number().required(),
  }),
});

let td_percent = 0;
let acos = 0;
let total_tpg = 0;
let perunit_tpg = 0;
let total_gtp = 0;
let perunit_gtp = 0;
let total_hpp = 0;
let perunit_hpp = 0;
let total_wind = 0;
let perunit_wind = 0;
let totcost_cgs = 0;
let unitcost_cgs = 0;
let totcost_ipp = 0;
let unitcost_ipp = 0;
let totcost_openacc = 0;
let unitcost_openacc = 0;
let unit_wind_pur = 0;
let unit_solar_pur = 0;
let unit_other_pur = 0;
let tot_trans_cost = 0;
let totpur_cost_trans = 0;
let unitpur_cost_trans = 0;
let unitpur_costsold = 0;
let unitcost_lineloss = 0;
let totdistcost1 = 0;
let totinc = 0;
let netdiscost = 0;

const totaldistcost1 = (values) => {
  let val =
    values.distr_cost1.opr_main_exp +
    values.distr_cost1.deprication +
    values.distr_cost1.int_fin_chrgs +
    values.distr_cost1.int_capital +
    values.distr_cost1.other_depts +
    values.distr_cost1.other_int_fc +
    values.distr_cost1.gross_revenue_dist;

  if (val !== "" && val !== 0 && val !== undefined) {
    totdistcost1 = parseFloat(val).toFixed(2);
  }
  return totdistcost1;
};

const totaldistcost2 = (values) => {
  totinc =
    values.distr_cost2.less_income !== "" ||
    values.distr_cost2.less_nontariff_inc !== ""
      ? values.distr_cost2.less_income + values.distr_cost2.less_nontariff_inc
      : 0;

  if (totinc !== "" && totinc !== undefined && totinc !== 0) {
    totinc = parseFloat(totinc).toFixed(2);
    netdiscost = totdistcost1 - totinc;
    netdiscost = parseFloat(netdiscost).toFixed(2);
  }

  return totinc;
};

const lineloss = (values) => {
  //console.log({ total_tpg, total_gtp, total_hpp, total_wind,p1:values.pur_cost1.totcost_wind_pur,p2: values.pur_cost1.totcost_solar_pur,p3: values.pur_cost1.totcost_other_pur});
  if (
    (total_tpg 
    ||
    total_gtp ||
    total_hpp ||
    total_wind)&&
   ( values.pur_cost1.totcost_wind_pur ||
    values.pur_cost1.totcost_solar_pur ||
    values.pur_cost1.totcost_other_pur)
  ) {
   // console.log("trueee valssss")
    let total_generation = total_tpg + total_gtp + total_hpp + total_wind;
    let total_powerpurchase =
      parseFloat(totcost_cgs) +
      parseFloat(totcost_ipp) +
      parseFloat(totcost_openacc) +
      parseFloat(values.pur_cost1.totcost_wind_pur) +
      parseFloat(values.pur_cost1.totcost_solar_pur) +
      parseFloat(values.pur_cost1.totcost_other_pur);
    let transmissionCost = tot_trans_cost;

    if (total_powerpurchase === NaN || total_powerpurchase === "NaN") {
      total_powerpurchase = 0;
    }
    totpur_cost_trans =
      parseFloat(total_generation) +
      parseFloat(total_powerpurchase) +
      parseFloat(transmissionCost);
    unitpur_cost_trans = (
      totpur_cost_trans /
      (parseFloat(values.avg_cost.eng_req_state) / 10)
    ).toFixed(2);

    if (totpur_cost_trans === NaN || totpur_cost_trans === "NaN") {
      totpur_cost_trans = 0;
    }
    if (unitpur_cost_trans === NaN || unitpur_cost_trans === "NaN") {
      unitpur_cost_trans = 0;
    }
    unitpur_costsold = (
      totpur_cost_trans /
      (parseFloat(values.avg_cost.eng_sales) / 10)
    ).toFixed(2);
    if (unitpur_costsold === NaN || unitpur_costsold === "NaN") {
      unitpur_costsold = 0;
    }
    unitcost_lineloss = unitpur_costsold - unitpur_cost_trans;

    if (
      totpur_cost_trans !== "" &&
      totpur_cost_trans !== 0 &&
      totpur_cost_trans !== undefined
    ) {
      totpur_cost_trans = parseFloat(totpur_cost_trans).toFixed(2);
    }
    if (
      unitcost_lineloss !== "" &&
      unitcost_lineloss !== undefined &&
      unitcost_lineloss !== 0
    ) {
      unitcost_lineloss = parseFloat(unitcost_lineloss).toFixed(2);
    }

    // console.log({
    //   total_generation,
    //   total_powerpurchase,
    //   totpur_cost_trans,
    //   transmissionCost,
    //   unitpur_costsold,
    //   unitpur_cost_trans,
    //   unitcost_lineloss,
    // });
    return totpur_cost_trans;
  } else {
   // console.log("///////false trans")
    return 0;
  }
};

const tdcal = (values, setFieldValue) => {
  let val =
    values.avg_cost.eng_req_state > 0 &&
    values.avg_cost.eng_sales <= values.avg_cost.eng_req_state
      ? (
          ((values.avg_cost.eng_req_state - values.avg_cost.eng_sales) /
            values.avg_cost.eng_req_state) *
          100
        ).toFixed(2)
      : 0;
  td_percent = val;
  // setFieldValue("avg_cost.TD_loss");
  return val;
};

const acoscal = (values, setFieldValue) => {
  let val = (
    values.avg_cost.tot_ARR > 0 && values.avg_cost.eng_sales > 0
      ? (values.avg_cost.tot_ARR / values.avg_cost.eng_sales) * 10
      : 0
  ).toFixed(2);
  acos = val;
  // setFieldValue("avg_cost.TD_loss");
  return val;
};

const totaltpg = (values) => {
  let val =
    values.gen_cost1.fc_tpg !== "" || values.gen_cost1.eng_chrg_tpg !== ""
      ? values.gen_cost1.fc_tpg + values.gen_cost1.eng_chrg_tpg
      : 0.0;

  total_tpg = parseFloat(val);

  perunit_tpg = (val / (values.avg_cost.eng_req_state / 10)).toFixed(2);
  if (perunit_tpg === NaN || perunit_tpg === "NaN") {
    perunit_tpg = 0;
  }
  // setFieldValue("avg_cost.TD_loss");
  return val;
};
const totalgtp = (values) => {
  let val =
    values.gen_cost1.fc_gtp !== "" || values.gen_cost1.eng_chrg_gtp !== ""
      ? values.gen_cost1.fc_gtp + values.gen_cost1.eng_chrg_gtp
      : 0.0;
  total_gtp = parseFloat(val);
  perunit_gtp = (val / (values.avg_cost.eng_req_state / 10)).toFixed(2);
  // setFieldValue("avg_cost.TD_loss");
  if (perunit_gtp === NaN || perunit_gtp === "NaN") {
    perunit_gtp = 0;
  }
  return val;
};
const totalhpp = (values) => {
  let val =
    values.gen_cost2.fc_hpp !== "" || values.gen_cost2.eng_chrg_hpp !== ""
      ? values.gen_cost2.fc_hpp + values.gen_cost2.eng_chrg_hpp
      : 0.0;
  total_hpp = parseFloat(val);
  perunit_hpp = (val / (values.avg_cost.eng_req_state / 10)).toFixed(2);
  // setFieldValue("avg_cost.TD_loss");
  if (perunit_hpp === NaN || perunit_hpp === "NaN") {
    perunit_hpp = 0;
  }
  return val;
};
const totalwind = (values) => {
  let val =
    values.gen_cost2.fc_wind !== "" || values.gen_cost2.eng_chrg_wind !== ""
      ? values.gen_cost2.fc_wind + values.gen_cost2.eng_chrg_wind
      : 0.0;
  total_wind = parseFloat(val);
  perunit_wind = (val / (values.avg_cost.eng_req_state / 10)).toFixed(4);
  // setFieldValue("avg_cost.TD_loss");
  if (perunit_wind === NaN || perunit_wind === "NaN") {
    perunit_wind = 0;
  }
  return val;
};
const totalcgs = (values) => {
  let val =
    values.pur_cost2.fc_pur_cgs !== "" ||
    values.pur_cost2.engchrg_pur_cgs !== ""
      ? values.pur_cost2.fc_pur_cgs + values.pur_cost2.engchrg_pur_cgs
      : 0.0;
  totcost_cgs = parseFloat(val);
  unitcost_cgs = (val / (values.avg_cost.eng_req_state / 10)).toFixed(2);
  // setFieldValue("avg_cost.TD_loss");

  if (unitcost_cgs === NaN || unitcost_cgs === "NaN") {
    unitcost_cgs = 0;
  }
  return val;
};
const totalipp = (values) => {
  let val =
    values.pur_cost2.fc_pur_ipp !== "" ||
    values.pur_cost2.engchrg_pur_ipp !== ""
      ? values.pur_cost2.fc_pur_ipp + values.pur_cost2.engchrg_pur_ipp
      : 0.0;

  if (val !== "" && val !== 0 && val !== undefined) {
    totcost_ipp = parseFloat(val).toFixed(2);
  }

  unitcost_ipp = (val / (values.avg_cost.eng_req_state / 10)).toFixed(2);
  // setFieldValue("avg_cost.TD_loss");
  if (unitcost_ipp === NaN || unitcost_ipp === "NaN") {
    unitcost_ipp = 0;
  }
  return totcost_ipp;
};
const totalopenacss = (values) => {
  let val =
    values.pur_cost2.fc_pur_open_acss !== "" ||
    values.pur_cost2.engchrg_pur_open_acss !== ""
      ? values.pur_cost2.fc_pur_open_acss +
        values.pur_cost2.engchrg_pur_open_acss
      : 0.0;
  totcost_openacc = parseFloat(val).toFixed(2);
  unitcost_openacc = (val / (values.avg_cost.eng_req_state / 10)).toFixed(2);
  // setFieldValue("avg_cost.TD_loss");
  if (unitcost_openacc === NaN || unitcost_openacc === "NaN") {
    unitcost_openacc = 0;
  }
  return totcost_openacc;
};

const unitWind = (values) => {
  let val =
    values.pur_cost1.totcost_wind_pur !== ""
      ? values.pur_cost1.totcost_wind_pur
      : 0.0;

  unit_wind_pur = (val / (values.avg_cost.eng_req_state / 10)).toFixed(2);
  // setFieldValue("avg_cost.TD_loss");

  if (unit_wind_pur === NaN || unit_wind_pur === "NaN") {
    unit_wind_pur = 0;
  }
  return unit_wind_pur;
};
const unitsolar = (values) => {
  let val =
    values.pur_cost1.totcost_solar_pur !== ""
      ? values.pur_cost1.totcost_solar_pur
      : 0.0;

  unit_solar_pur = (val / (values.avg_cost.eng_req_state / 10)).toFixed(2);
  // setFieldValue("avg_cost.TD_loss");
  if (unit_solar_pur === NaN || unit_solar_pur === "NaN") {
    unit_solar_pur = 0;
  }
  return unit_solar_pur;
};
const unitOther = (values) => {
  let val =
    values.pur_cost1.totcost_other_pur !== ""
      ? values.pur_cost1.totcost_other_pur
      : 0.0;

  unit_other_pur = (val / (values.avg_cost.eng_req_state / 10)).toFixed(2);
  // setFieldValue("avg_cost.TD_loss");
  if (unit_other_pur === NaN || unit_other_pur === "NaN") {
    unit_other_pur = 0;
  }
  return unit_other_pur;
};

const totaltransmission = (values) => {
  let val =
    values.trans_cost.tn_trans_chrg !== "" ||
    values.trans_cost.pgcil_chrgs !== "" ||
    values.trans_cost.state_des_chrgs
      ? values.trans_cost.tn_trans_chrg +
        values.trans_cost.pgcil_chrgs +
        values.trans_cost.state_des_chrgs
      : 0.0;
  tot_trans_cost = parseFloat(val).toFixed(2);

  return tot_trans_cost;
};

// const start = 1997;
// const end = 2050;
// const yearOption = [];

// const stateOption = [
//   {
//     label: "Tamil Nadu",
//     value: "Tamil Nadu",
//   },
//   {
//     label: "Kerela",
//     value: "Kerela",
//   },
//   {
//     label: "Andhra",
//     value: "Andhra",
//   },
// ];
const required = <span style={{ color: "red" }}>*</span>;

const NewTariff = (props) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("cag_key")}`,
    },
  };
  const config2 = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("cag_key")}`,
      "Content-Type": "application/json",
    },
  };
  const [formShow, setFormShow] = useState(false);
  const [tariffData, setValues] = useState({});
  const [next, setNext] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [statesOption, setStates] = useState([]);
  const [yearOption, setYear] = useState([]);
  useEffect(() => {
    const data = states1();
    let arr = [];
    data.map((s) => {
      arr.push({ label: s.state, value: s.state });
    });
    setStates(arr);
    setYear(year1());
  }, []);

  return (
    <>
      <div className="w-100 mb-5">
        <ToastContainer containerId={"dx"} />
        <div className="row align-items-center mb-3">
          <div className="col-12">
            <div className="d-flex flex-row align-items-center  justify-content-start">
              <div
                className="mr-3"
                style={{ cursor: "pointer" }}
                onClick={() => props.close(false)}
              >
                <IconContext.Provider
                  value={{
                    size: "40px",
                    color: "#6a707e",
                  }}
                >
                  <MdKeyboardBackspace />
                </IconContext.Provider>
              </div>

              <h4 className="header-text " style={{ marginLeft: "20px" }}>
                Tariff Analysis Data
              </h4>
            </div>
          </div>
          {/* <div className="col-3">
            
          </div>*/}
        </div>

        {!next ? (
          <div className="card-container">
            <Formik
              initialValues={{
                state: "",
                year: "",
              }}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={yup.object({
                state: yup.string().required("Please select State"),
                year: yup.number().required("Please select year"),
              })}
              onSubmit={async (values, formik) => {
                //  console.log({ values });
                try {
                  let result = await axios.post(
                    Global.base_url + "tariff/check",
                    values,
                    config2
                  );
                  //  console.log(result);
                  if (result.status === 200) {
                    setValues(values);
                    return setNext(true);
                    // return;
                  }
                } catch (error) {
                  //     console.log({ error });
                  if (error && error.response) {
                    if (error.response.status === 403) {
                      return formik.setFieldError(
                        "state",
                        "Tariff already created"
                      );
                    }
                    if (error.response.status === 401) {
                      return formik.setFieldError(
                        "state",
                        "Tariff is not created"
                      );
                    }
                  }
                }
              }}
            >
              {(formik) => {
                return (
                  <>
                    <div
                      className="form-group mb-3"
                      style={{ position: "relative" }}
                      id="state"
                    >
                      <label htmlFor="status" className="create-label">
                        State {required}
                      </label>
                      <Select
                        value={{
                          value: formik.values.state,
                          label: formik.values.state,
                        }}
                        onChange={(p) => {
                          formik.setFieldValue("state", p.value);
                        }}
                        options={statesOption}
                        className="admim__Addproduct--dropdown  "
                        placeholder="Select State"
                        id="status"
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            padding: "10px 10px",
                            border: formik.errors.state
                              ? "1px solid red"
                              : "1px solid #B3CDFF80",
                          }),
                        }}
                      />

                      {formik.errors.state && (
                        <div className="tariff-error-position">
                          <p>{formik.errors.state}</p>
                        </div>
                      )}
                    </div>
                    <div
                      className="form-group mb-3 "
                      style={{ position: "relative" }}
                      id="a123"
                    >
                      <label htmlFor="year" className="create-label">
                        Year {required}
                      </label>
                      <Select
                        value={{
                          label: formik.values.year,
                          value: formik.values.year,
                        }}
                        onChange={(p) => {
                          formik.setFieldValue("year", p.value);
                        }}
                        options={yearOption}
                        className="admim__Addproduct--dropdown "
                        placeholder="Select Year"
                        id="state"
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            padding: "10px 10px",
                            border: formik.errors.year
                              ? "1px solid red"
                              : "1px solid #B3CDFF80",
                          }),
                        }}
                      />

                      {formik.errors.year && (
                        <div className="tariff-error-position">
                          <p>{formik.errors.year}</p>
                        </div>
                      )}
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-end mt-2">
                      <Button
                        variant="contained"
                        id="createBtn"
                        className={"createBtn"}
                        onClick={formik.handleSubmit}
                        // disabled={true}
                      >
                        Create
                      </Button>
                    </div>
                  </>
                );
              }}
            </Formik>
          </div>
        ) : (
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              tariff_issued: "",
              petition_filed: "",
              avg_cost: {
                eng_req_state: "",
                eng_sales: "",
                TD_loss: td_percent,
                tot_ARR: "",
                power_sale_revenue: "",
                ACOS: acos,
              },
              gen_cost1: {
                quanum_tpg: "",
                fc_tpg: "",
                eng_chrg_tpg: "",
                tot_cost_tpg: total_tpg,
                unit_cost_tpg: perunit_tpg,
                quanum_gtp: "",
                fc_gtp: "",
                eng_chrg_gtp: "",
                tot_cost_gtp: total_gtp,
                unit_cost_gtp: perunit_gtp,
              },
              gen_cost2: {
                quanum_hpp: "",
                fc_hpp: "",
                eng_chrg_hpp: 0,
                tot_cost_hpp: total_hpp,
                unit_cost_hpp: perunit_hpp,
                quanum_wind: "",
                fc_wind: 0,
                eng_chrg_wind: "",
                tot_cost_wind: total_wind,
                unit_cost_wind: perunit_wind,
              },
              pur_cost1: {
                quanum_wind_pur: "",
                totcost_wind_pur: "",
                unitcost_wind_pur: unit_wind_pur,
                quanum_solar_pur: "",
                totcost_solar_pur: "",
                unitcost_solar_pur: unit_solar_pur,

                quanum_other_pur: "",
                totcost_other_pur: "",
                unitcost_other_pur: unit_other_pur,
              },
              pur_cost2: {
                quanum_pur_cgs: "",
                fc_pur_cgs: "",
                engchrg_pur_cgs: "",
                totcost_pur_cgs: totcost_cgs,
                unitcost_pur_cgs: unitcost_cgs,

                quanum_pur_ipp: "",
                fc_pur_ipp: "",
                engchrg_pur_ipp: "",
                totcost_pur_ipp: totcost_ipp,
                unitcost_pur_ipp: unitcost_ipp,

                quanum_pur_open_acss: "",
                fc_pur_open_acss: "",
                engchrg_pur_open_acss: "",
                totcost_pur_open_acss: totcost_openacc,
                unitcost_pur_open_acss: unitcost_openacc,
              },
              trans_cost: {
                tn_trans_chrg: "",
                pgcil_chrgs: "",
                state_des_chrgs: "",
                tot_trans_cost: tot_trans_cost,
              },
              lineloss_cost: {
                tot_pur_cost: totpur_cost_trans,
                unit_pur_cost: unitpur_cost_trans,
                unit_pur_cost_sold: unitpur_costsold,
                unit_cost_lineloss: unitcost_lineloss,
              },
              distr_cost1: {
                opr_main_exp: "",
                deprication: "",
                int_fin_chrgs: "",
                int_capital: 0,
                other_depts: 0,

                other_int_fc: "",
                gross_revenue_dist: totdistcost1,
                employee_cost: 0,
              },
              distr_cost2: {
                less_income: "",
                less_nontariff_inc: "",
                less_total_inc: totinc,
                net_distr_cost: netdiscost,
              },
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              //   console.log({ values });

              try {
                const data = {
                  formValues: values,
                  tariffData,
                };
                const response = await axios.post(
                  Global.base_url + "tariff/new",
                  data,
                  config2
                );
                if (response.status === 200) {
                  props.close(false);
                  return props.toaster(1, "New Tariff Created");
                }
              } catch (error) {
                if (
                  (error.response && error.response.status === 401) ||
                  (error.response.status === 400 &&
                    error.response.status !== undefined)
                ) {
                  // props.close(false)
                  // toast.error("Tariff Cannot be Created", {
                  //   containerId: "dx",
                  // });
                  return props.toaster(0, "Tariff Cannot be Created");
                }
                //    console.log("error", error);
              }
            }}
          >
            {(formik) => {
              let { values, errors } = formik;
              // console.log({ tariffData });
              // console.log({ acos });
              return (
                <>
                  <div className="mb-4">
                    <div style={{ marginTop: "30px" }}>
                      <Card
                        className="shadow"
                        style={{ backgroundColor: "white" }}
                      >
                        <CardHeader
                          className="border-0"
                          style={{ backgroundColor: "#bdbdbd" }}
                        >
                          <h2 className="mb-0" style={{ textAlign: "center" }}>
                            TARIFF ORDER {tariffData.year}
                          </h2>
                        </CardHeader>
                        <CardHeader
                          className="border-0"
                          style={{ backgroundColor: "white" }}
                        >
                          <div className="row">
                            <div className="col">
                              <h5
                                className="mb-0"
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "white",
                                }}
                              >
                                Tariff Issued by {stateErc(tariffData.state)} on
                              </h5>
                              <div
                                style={{
                                  position: "relative",
                                  textAlign: "center",
                                }}
                              >
                                <input
                                  type="date"
                                  min={`${tariffData.year}-01-01`}
                                  className={
                                    formik.errors && formik.errors.tariff_issued
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  value={values.tariff_issued}
                                  onChange={formik.handleChange(
                                    "tariff_issued"
                                  )}
                                />
                                {formik.errors
                                  ? formik.errors.tariff_issued && (
                                      <div className="table_error_position">
                                        <p>Required</p>
                                      </div>
                                    )
                                  : null}
                              </div>
                            </div>
                            <div className="col">
                              <h5
                                className="mb-0"
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "white",
                                }}
                              >
                                Petition filed by {stateDist(tariffData.state)}{" "}
                                on
                              </h5>
                              <div
                                style={{
                                  position: "relative",
                                  textAlign: "center",
                                }}
                              >
                                <input
                                  type="date"
                                  className={
                                    formik.errors &&
                                    formik.errors.petition_filed
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  value={values.petition_filed}
                                  onChange={formik.handleChange(
                                    "petition_filed"
                                  )}
                                />
                                {formik.errors
                                  ? formik.errors.petition_filed && (
                                      <div className="table_error_position">
                                        <p>Required</p>
                                      </div>
                                    )
                                  : null}
                              </div>
                            </div>
                          </div>
                        </CardHeader>
                      </Card>
                    </div>
                    <div className="row" style={{ marginTop: "30px" }}>
                      <div className="col-md-6">
                        <div>
                          <Card className="shadow">
                            <Table
                              className="align-items-center table-flush acos_table"
                              responsive
                            >
                              <thead style={{ backgroundColor: "#bdbdbd" }}>
                                <tr>
                                  <td style={{ width: "50%" }}>
                                    ENERGY REQUIREMENT
                                  </td>
                                  <td style={{ width: "35%" }}>
                                    FY {tariffData.year}-{tariffData.year + 1}
                                  </td>
                                  <td style={{ width: "15%" }}></td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    Energy required at State Boundary
                                    {required}
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.avg_cost
                                            ? formik.errors.avg_cost
                                                .eng_req_state
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.avg_cost.eng_req_state}
                                        onChange={formik.handleChange(
                                          "avg_cost.eng_req_state"
                                        )}
                                      />
                                      {formik.errors && formik.errors.avg_cost
                                        ? formik.errors.avg_cost
                                            .eng_req_state && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>MU</td>
                                </tr>
                              </tbody>
                            </Table>
                          </Card>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <Card className="shadow">
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <thead style={{ backgroundColor: "#bdbdbd" }}>
                                <tr>
                                  <td colSpan={3} style={{ width: "50%" }}>
                                    TOTAL ENERGY SALES
                                  </td>
                                  <td style={{ width: "35%" }}>
                                    {" "}
                                    FY {tariffData.year}-{tariffData.year + 1}
                                  </td>
                                  <td style={{ width: "15%" }}></td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td colSpan={3}>Energy Sales</td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.avg_cost
                                            ? formik.errors.avg_cost.eng_sales
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.avg_cost.eng_sales}
                                        onChange={formik.handleChange(
                                          "avg_cost.eng_sales"
                                        )}
                                      />
                                      {formik.errors && formik.errors.avg_cost
                                        ? formik.errors.avg_cost.eng_sales && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>MU</td>
                                </tr>
                              </tbody>
                            </Table>
                          </Card>
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: "30px" }}>
                      <div className="col-md-6">
                        <div>
                          <Card className="shadow">
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <thead style={{ backgroundColor: "#bdbdbd" }}>
                                <tr>
                                  <td colSpan={3} style={{ width: "50%" }}>
                                    T&D LOSSES
                                  </td>
                                  <td style={{ width: "35%" }}>
                                    FY {tariffData.year}-{tariffData.year + 1}
                                  </td>
                                  <td style={{ width: "15%" }}></td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td colSpan={3}>
                                    Transmission & Distribution losses
                                  </td>
                                  <td>{tdcal(values, formik.setFieldValue)}</td>
                                  <td>%</td>
                                </tr>
                              </tbody>
                            </Table>
                          </Card>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <Card className="shadow">
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <thead style={{ backgroundColor: "#bdbdbd" }}>
                                <tr>
                                  <td colSpan={3} style={{ width: "50%" }}>
                                    AGGREGATE REVENUE (ARR)
                                  </td>
                                  <td style={{ width: "35%" }}>
                                    FY {tariffData.year}-{tariffData.year + 1}
                                  </td>
                                  <td style={{ width: "15%" }}></td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td colSpan={3} style={{ width: "50%" }}>
                                    Total ARR
                                  </td>
                                  <td style={{ width: "35%" }}>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.avg_cost
                                            ? formik.errors.avg_cost.tot_ARR
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.avg_cost.tot_ARR}
                                        onChange={formik.handleChange(
                                          "avg_cost.tot_ARR"
                                        )}
                                      />
                                      {formik.errors && formik.errors.avg_cost
                                        ? formik.errors.avg_cost.tot_ARR && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td style={{ width: "15%" }}>
                                    &#x20b9; in Crs
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={3}>
                                    Revenue from sale of Power
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.avg_cost
                                            ? formik.errors.avg_cost
                                                .power_sale_revenue
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={
                                          values.avg_cost.power_sale_revenue
                                        }
                                        onChange={formik.handleChange(
                                          "avg_cost.power_sale_revenue"
                                        )}
                                      />
                                      {formik.errors && formik.errors.avg_cost
                                        ? formik.errors.avg_cost
                                            .power_sale_revenue && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>&#x20b9; in Crs</td>
                                </tr>
                              </tbody>
                            </Table>
                          </Card>
                        </div>
                      </div>
                    </div>

                    <div style={{ marginTop: "30px" }}>
                      <Card
                        className="shadow"
                        style={{ backgroundColor: "#b5b4b4" }}
                      >
                        <Table
                          className="align-items-center table-flush"
                          responsive
                        >
                          <tbody>
                            <tr>
                              <td style={{ paddingTop: "30px", width: "30%" }}>
                                AVERAGE COST OF SUPPLY (ACoS)
                              </td>
                              <td style={{ paddingTop: "30px" }}>=</td>
                              <td style={{ width: "30%" }}>
                                {" "}
                                ANNUAL REVENUE REQUIREMENT
                                <hr />
                                TOTAL ENERGY SALES FOR THE YEAR
                              </td>
                              <td
                                style={{
                                  paddingTop: "30px",
                                  textAlign: "center",
                                }}
                              >
                                {acoscal(values, formik.setFieldValue)}
                              </td>
                              <td style={{ paddingTop: "30px" }}>INR</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card>
                    </div>

                    <div
                      onClick={() => setOpen(!open)}
                      style={{
                        border:
                          (formik.errors && formik.errors.gen_cost1) ||
                          formik.errors.gen_cost2
                            ? "1px solid #dc3545 "
                            : "1px solid rgb(184, 184, 184)",
                      }}
                      className=" accordion-container"
                    >
                      <div className="accordion-inside">
                        <h6 className="mb-0 accordion-header-text">
                          1. GENERATION COSTS
                        </h6>
                        <div>
                          <IconContext.Provider
                            value={{
                              size: "40px",
                              color: "#42BD41",
                            }}
                          >
                            <RiArrowDropDownLine />
                          </IconContext.Provider>
                        </div>
                      </div>
                    </div>
                    <Collapse isOpen={open}>
                      <Row>
                        <div className="col">
                          <Card className="shadow">
                            <CardHeader
                              className="border-0"
                              style={{ backgroundColor: "#bdbdbd" }}
                            >
                              <h5 className="mb-0">
                                1.1 GENERATION FROM OWN STATIONS
                                (NON-RENEWABLES)
                              </h5>
                            </CardHeader>
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <tbody>
                                <tr>
                                  <td>Quantum of thermal power generation</td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.gen_cost1
                                            ? formik.errors.gen_cost1.quanum_tpg
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.gen_cost1.quanum_tpg}
                                        onChange={formik.handleChange(
                                          "gen_cost1.quanum_tpg"
                                        )}
                                      />
                                      {formik.errors && formik.errors.gen_cost1
                                        ? formik.errors.gen_cost1
                                            .quanum_tpg && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>MU</td>
                                </tr>
                                <tr>
                                  <td>
                                    Fixed Cost of thermal power generation
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.gen_cost1
                                            ? formik.errors.gen_cost1.fc_tpg
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.gen_cost1.fc_tpg}
                                        onChange={formik.handleChange(
                                          "gen_cost1.fc_tpg"
                                        )}
                                      />
                                      {formik.errors && formik.errors.gen_cost1
                                        ? formik.errors.gen_cost1.fc_tpg && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>
                                    Energy charges for thermal power generation
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.gen_cost1
                                            ? formik.errors.gen_cost1
                                                .eng_chrg_tpg
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.gen_cost1.eng_chrg_tpg}
                                        onChange={formik.handleChange(
                                          "gen_cost1.eng_chrg_tpg"
                                        )}
                                      />
                                      {formik.errors && formik.errors.gen_cost1
                                        ? formik.errors.gen_cost1
                                            .eng_chrg_tpg && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>
                                    Total cost of thermal power generation
                                  </td>
                                  <td>{totaltpg(values)}</td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td style={{ fontWeight: "bold" }}>
                                    Per unit cost of thermal power generation
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    {perunit_tpg}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    INR
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Quantum of power generation from Gas Turbine
                                    Plants
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.gen_cost1
                                            ? formik.errors.gen_cost1.quanum_gtp
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.gen_cost1.quanum_gtp}
                                        onChange={formik.handleChange(
                                          "gen_cost1.quanum_gtp"
                                        )}
                                      />
                                      {formik.errors && formik.errors.gen_cost1
                                        ? formik.errors.gen_cost1
                                            .quanum_gtp && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>MU</td>
                                </tr>
                                <tr>
                                  <td>
                                    Fixed Cost of power generation from Gas
                                    Turbine Plants
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.gen_cost1
                                            ? formik.errors.gen_cost1.fc_gtp
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.gen_cost1.fc_gtp}
                                        onChange={formik.handleChange(
                                          "gen_cost1.fc_gtp"
                                        )}
                                      />
                                      {formik.errors && formik.errors.gen_cost1
                                        ? formik.errors.gen_cost1.fc_gtp && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>
                                    Energy charges for power generation from Gas
                                    Turbine Plants
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.gen_cost1
                                            ? formik.errors.gen_cost1
                                                .eng_chrg_gtp
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.gen_cost1.eng_chrg_gtp}
                                        onChange={formik.handleChange(
                                          "gen_cost1.eng_chrg_gtp"
                                        )}
                                      />
                                      {formik.errors && formik.errors.gen_cost1
                                        ? formik.errors.gen_cost1
                                            .eng_chrg_gtp && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>
                                    Total cost of power generation from Gas
                                    Turbine Plants
                                  </td>
                                  <td>{totalgtp(values)}</td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td style={{ fontWeight: "bold" }}>
                                    Per unit cost of power generation from Gas
                                    Turbine Plants
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    {perunit_gtp}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    INR
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Card>
                        </div>
                      </Row>
                      <Row style={{ marginTop: "50px" }}>
                        <div className="col">
                          <Card className="shadow">
                            <CardHeader
                              className="border-0"
                              style={{ backgroundColor: "#bdbdbd" }}
                            >
                              <h5 className="mb-0">
                                1.2 GENERATION FROM OWN STATIONS (RENEWABLES)
                              </h5>
                            </CardHeader>
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    Quantum of power generation from
                                    Hydroelectric power plants
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.gen_cost2
                                            ? formik.errors.gen_cost2.quanum_hpp
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.gen_cost2.quanum_hpp}
                                        onChange={formik.handleChange(
                                          "gen_cost2.quanum_hpp"
                                        )}
                                      />
                                      {formik.errors && formik.errors.gen_cost2
                                        ? formik.errors.gen_cost2
                                            .quanum_hpp && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>MU</td>
                                </tr>
                                <tr>
                                  <td>
                                    Fixed Cost of power generation from
                                    Hydroelectric power plants
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.gen_cost2
                                            ? formik.errors.gen_cost2.fc_hpp
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.gen_cost2.fc_hpp}
                                        onChange={formik.handleChange(
                                          "gen_cost2.fc_hpp"
                                        )}
                                      />
                                      {formik.errors && formik.errors.gen_cost2
                                        ? formik.errors.gen_cost2.fc_hpp && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>
                                    Energy charges for power generation from
                                    Hydroelectric power plants
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.gen_cost2
                                            ? formik.errors.gen_cost2
                                                .eng_chrg_hpp
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.gen_cost2.eng_chrg_hpp}
                                        onChange={formik.handleChange(
                                          "gen_cost2.eng_chrg_hpp"
                                        )}
                                      />
                                      {formik.errors && formik.errors.gen_cost2
                                        ? formik.errors.gen_cost2
                                            .eng_chrg_hpp && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>
                                    Total cost of power generation from
                                    Hydroelectric power plants
                                  </td>
                                  <td>{totalhpp(values)}</td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td style={{ fontWeight: "bold" }}>
                                    Per unit cost of power generation from
                                    Hydroelectric power plants
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    {perunit_hpp}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    INR
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Quantum of power generation from wind energy{" "}
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.gen_cost2
                                            ? formik.errors.gen_cost2
                                                .quanum_wind
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.gen_cost2.quanum_wind}
                                        onChange={formik.handleChange(
                                          "gen_cost2.quanum_wind"
                                        )}
                                      />
                                      {formik.errors && formik.errors.gen_cost2
                                        ? formik.errors.gen_cost2
                                            .quanum_wind && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>MU</td>
                                </tr>
                                <tr>
                                  <td>
                                    Fixed Cost of power generation from wind
                                    energy
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.gen_cost2
                                            ? formik.errors.gen_cost2.fc_wind
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.gen_cost2.fc_wind}
                                        onChange={formik.handleChange(
                                          "gen_cost2.fc_wind"
                                        )}
                                      />
                                      {formik.errors && formik.errors.gen_cost2
                                        ? formik.errors.gen_cost2.fc_wind && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>
                                    Energy charges for power generation from
                                    wind energy
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.gen_cost2
                                            ? formik.errors.gen_cost2
                                                .eng_chrg_wind
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.gen_cost2.eng_chrg_wind}
                                        onChange={formik.handleChange(
                                          "gen_cost2.eng_chrg_wind"
                                        )}
                                      />
                                      {formik.errors && formik.errors.gen_cost2
                                        ? formik.errors.gen_cost2
                                            .eng_chrg_wind && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>
                                    Total cost of power generation from Gas wind
                                    energy
                                  </td>
                                  <td>{totalwind(values)}</td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td style={{ fontWeight: "bold" }}>
                                    Per unit cost of power generation from wind
                                    energy
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    {perunit_wind}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    INR
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Card>
                        </div>
                      </Row>
                    </Collapse>
                    <div
                      onClick={() => setOpen1(!open1)}
                      style={{
                        border:
                          (formik.errors && formik.errors.pur_cost1) ||
                          formik.errors.pur_cost2
                            ? "1px solid #dc3545 "
                            : "1px solid rgb(184, 184, 184)",
                      }}
                      className=" accordion-container"
                    >
                      <div className="accordion-inside">
                        <h6 className="mb-0 accordion-header-text">
                          2. POWER PURCHASE COSTS
                        </h6>
                        <div>
                          <IconContext.Provider
                            value={{
                              size: "40px",
                              color: "#42BD41",
                            }}
                          >
                            <RiArrowDropDownLine />
                          </IconContext.Provider>
                        </div>
                      </div>
                    </div>
                    <Collapse isOpen={open1}>
                      <Row>
                        <div className="col">
                          <Card className="shadow">
                            <CardHeader
                              className="border-0"
                              style={{ backgroundColor: "#bdbdbd" }}
                            >
                              <h5 className="mb-0">
                                2.1 POWER PURCHASE COSTS ( RENEWABLES )
                              </h5>
                            </CardHeader>
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <tbody>
                                <tr>
                                  <td>Quantum of wind energy purchased</td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.pur_cost1
                                            ? formik.errors.pur_cost1
                                                .quanum_wind_pur
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.pur_cost1.quanum_wind_pur}
                                        onChange={formik.handleChange(
                                          "pur_cost1.quanum_wind_pur"
                                        )}
                                      />
                                      {formik.errors && formik.errors.pur_cost1
                                        ? formik.errors.pur_cost1
                                            .quanum_wind_pur && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>MU</td>
                                </tr>
                                <tr>
                                  <td>Total Cost of wind energy purchased </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.pur_cost1
                                            ? formik.errors.pur_cost1
                                                .totcost_wind_pur
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={
                                          values.pur_cost1.totcost_wind_pur
                                        }
                                        onChange={formik.handleChange(
                                          "pur_cost1.totcost_wind_pur"
                                        )}
                                      />
                                      {formik.errors && formik.errors.pur_cost1
                                        ? formik.errors.pur_cost1
                                            .totcost_wind_pur && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td style={{ fontWeight: "bold" }}>
                                    Per unit cost of wind energy purchased
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    {unitWind(values)}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    INR
                                  </td>
                                </tr>
                                <tr>
                                  <td>Quanum of solar energy purchased</td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.pur_cost1
                                            ? formik.errors.pur_cost1
                                                .quanum_solar_pur
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={
                                          values.pur_cost1.quanum_solar_pur
                                        }
                                        onChange={formik.handleChange(
                                          "pur_cost1.quanum_solar_pur"
                                        )}
                                      />
                                      {formik.errors && formik.errors.pur_cost1
                                        ? formik.errors.pur_cost1
                                            .quanum_solar_pur && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>MU</td>
                                </tr>
                                <tr>
                                  <td>Total cost of solar energy purchased</td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.pur_cost1
                                            ? formik.errors.pur_cost1
                                                .totcost_solar_pur
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={
                                          values.pur_cost1.totcost_solar_pur
                                        }
                                        onChange={formik.handleChange(
                                          "pur_cost1.totcost_solar_pur"
                                        )}
                                      />
                                      {formik.errors && formik.errors.pur_cost1
                                        ? formik.errors.pur_cost1
                                            .totcost_solar_pur && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td style={{ fontWeight: "bold" }}>
                                    Per unit cost of solar energy purchased
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    {unitsolar(values)}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    INR
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Quanum of other renewable energy purchased
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.pur_cost1
                                            ? formik.errors.pur_cost1
                                                .quanum_other_pur
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={
                                          values.pur_cost1.quanum_other_pur
                                        }
                                        onChange={formik.handleChange(
                                          "pur_cost1.quanum_other_pur"
                                        )}
                                      />
                                      {formik.errors && formik.errors.pur_cost1
                                        ? formik.errors.pur_cost1
                                            .quanum_other_pur && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>MU</td>
                                </tr>
                                <tr>
                                  <td>
                                    Total cost of other renewable energy
                                    purchased
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.pur_cost1
                                            ? formik.errors.pur_cost1
                                                .totcost_other_pur
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={
                                          values.pur_cost1.totcost_other_pur
                                        }
                                        onChange={formik.handleChange(
                                          "pur_cost1.totcost_other_pur"
                                        )}
                                      />
                                      {formik.errors && formik.errors.pur_cost1
                                        ? formik.errors.pur_cost1
                                            .totcost_other_pur && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td style={{ fontWeight: "bold" }}>
                                    Per unit cost of other energy purchased
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    {unitOther(values)}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    INR
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Card>
                        </div>
                      </Row>

                      <Row style={{ marginTop: "50px" }}>
                        <div className="col">
                          <Card className="shadow">
                            <CardHeader
                              className="border-0"
                              style={{ backgroundColor: "#bdbdbd" }}
                            >
                              <h5 className="mb-0">
                                2.2 POWER PURCHASE COSTS (NON-RENEWABLES)
                              </h5>
                            </CardHeader>
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    Quantum of power purchased from Central
                                    Generating Station (CGS)
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.pur_cost2
                                            ? formik.errors.pur_cost2
                                                .quanum_pur_cgs
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.pur_cost2.quanum_pur_cgs}
                                        onChange={formik.handleChange(
                                          "pur_cost2.quanum_pur_cgs"
                                        )}
                                      />
                                      {formik.errors && formik.errors.pur_cost2
                                        ? formik.errors.pur_cost2
                                            .quanum_pur_cgs && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>MU</td>
                                </tr>
                                <tr>
                                  <td>
                                    Fixed Cost of power purchased from CGS
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.pur_cost2
                                            ? formik.errors.pur_cost2.fc_pur_cgs
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.pur_cost2.fc_pur_cgs}
                                        onChange={formik.handleChange(
                                          "pur_cost2.fc_pur_cgs"
                                        )}
                                      />
                                      {formik.errors && formik.errors.pur_cost2
                                        ? formik.errors.pur_cost2
                                            .fc_pur_cgs && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>
                                    Energy charges for power purchased from CGS
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.pur_cost2
                                            ? formik.errors.pur_cost2
                                                .engchrg_pur_cgs
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.pur_cost2.engchrg_pur_cgs}
                                        onChange={formik.handleChange(
                                          "pur_cost2.engchrg_pur_cgs"
                                        )}
                                      />
                                      {formik.errors && formik.errors.pur_cost2
                                        ? formik.errors.pur_cost2
                                            .engchrg_pur_cgs && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>
                                    Total cost of power purchased from CGS
                                  </td>
                                  <td>{totalcgs(values)}</td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td style={{ fontWeight: "bold" }}>
                                    Per unit cost of power purchased from CGS
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    {unitcost_cgs}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    INR
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Quantum of power purchased from Independent
                                    Power Producers (IPP)
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.pur_cost2
                                            ? formik.errors.pur_cost2
                                                .quanum_pur_ipp
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.pur_cost2.quanum_pur_ipp}
                                        onChange={formik.handleChange(
                                          "pur_cost2.quanum_pur_ipp"
                                        )}
                                      />
                                      {formik.errors && formik.errors.pur_cost2
                                        ? formik.errors.pur_cost2
                                            .quanum_pur_ipp && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>MU</td>
                                </tr>
                                <tr>
                                  <td>
                                    Fixed Cost of power purchased from IPP
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.pur_cost2
                                            ? formik.errors.pur_cost2.fc_pur_ipp
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.pur_cost2.fc_pur_ipp}
                                        onChange={formik.handleChange(
                                          "pur_cost2.fc_pur_ipp"
                                        )}
                                      />
                                      {formik.errors && formik.errors.pur_cost2
                                        ? formik.errors.pur_cost2
                                            .fc_pur_ipp && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>
                                    Energy charges for power purchased from IPP
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.pur_cost2
                                            ? formik.errors.pur_cost2
                                                .engchrg_pur_ipp
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.pur_cost2.engchrg_pur_ipp}
                                        onChange={formik.handleChange(
                                          "pur_cost2.engchrg_pur_ipp"
                                        )}
                                      />
                                      {formik.errors && formik.errors.pur_cost2
                                        ? formik.errors.pur_cost2
                                            .engchrg_pur_ipp && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>
                                    Total cost of power purchased from IPP
                                  </td>
                                  <td>{totalipp(values)}</td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td style={{ fontWeight: "bold" }}>
                                    Per unit cost of power purchased from IPP
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    {unitcost_ipp}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    INR
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Quantum of power purchased from Traders
                                    (Open Access)
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.pur_cost2
                                            ? formik.errors.pur_cost2
                                                .quanum_pur_open_acss
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={
                                          values.pur_cost2.quanum_pur_open_acss
                                        }
                                        onChange={formik.handleChange(
                                          "pur_cost2.quanum_pur_open_acss"
                                        )}
                                      />
                                      {formik.errors && formik.errors.pur_cost2
                                        ? formik.errors.pur_cost2
                                            .quanum_pur_open_acss && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>MU</td>
                                </tr>
                                <tr>
                                  <td>
                                    Fixed Cost of power purchased from Traders
                                    (Open Access)
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.pur_cost2
                                            ? formik.errors.pur_cost2
                                                .fc_pur_open_acss
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={
                                          values.pur_cost2.fc_pur_open_acss
                                        }
                                        onChange={formik.handleChange(
                                          "pur_cost2.fc_pur_open_acss"
                                        )}
                                      />
                                      {formik.errors && formik.errors.pur_cost2
                                        ? formik.errors.pur_cost2
                                            .fc_pur_open_acss && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>
                                    Energy charges for power purchased from
                                    Traders (Open Access)
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.pur_cost2
                                            ? formik.errors.pur_cost2
                                                .engchrg_pur_open_acss
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={
                                          values.pur_cost2.engchrg_pur_open_acss
                                        }
                                        onChange={formik.handleChange(
                                          "pur_cost2.engchrg_pur_open_acss"
                                        )}
                                      />
                                      {formik.errors && formik.errors.pur_cost2
                                        ? formik.errors.pur_cost2
                                            .engchrg_pur_open_acss && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>
                                    Total cost of power purchased from Traders
                                    (Open Access)
                                  </td>
                                  <td>{totalopenacss(values)}</td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td style={{ fontWeight: "bold" }}>
                                    Per unit cost of power purchased from
                                    Traders (Open Access)
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    {unitcost_openacc}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    INR
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Card>
                        </div>
                      </Row>
                    </Collapse>
                    <div
                      onClick={() => setOpen2(!open2)}
                      style={{
                        border:
                          (formik.errors && formik.errors.trans_cost) ||
                          formik.errors.trans_cost
                            ? "1px solid #dc3545 "
                            : "1px solid rgb(184, 184, 184)",
                      }}
                      className=" accordion-container"
                    >
                      <div className="accordion-inside">
                        <h6 className="mb-0 accordion-header-text">
                          3. TRANSMISSION COSTS
                        </h6>
                        <div>
                          <IconContext.Provider
                            value={{
                              size: "40px",
                              color: "#42BD41",
                            }}
                          >
                            <RiArrowDropDownLine />
                          </IconContext.Provider>
                        </div>
                      </div>
                    </div>
                    <Collapse isOpen={open2}>
                      <Row style={{ marginBottom: "50px" }}>
                        <div className="col">
                          <Card className="shadow">
                            <CardHeader
                              className="border-0"
                              style={{ backgroundColor: "#bdbdbd" }}
                            >
                              <h5 className="mb-0">
                                3.1 TANTRANSCO charges including PGCIL charges
                                and SLDC Charges
                              </h5>
                            </CardHeader>
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    Tamil Nadu Transmission Corporation Ltd
                                    Charges
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.trans_cost
                                            ? formik.errors.trans_cost
                                                .tn_trans_chrg
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.trans_cost.tn_trans_chrg}
                                        onChange={formik.handleChange(
                                          "trans_cost.tn_trans_chrg"
                                        )}
                                      />
                                      {formik.errors && formik.errors.trans_cost
                                        ? formik.errors.trans_cost
                                            .tn_trans_chrg && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>
                                    Power Grid Corporation of India Ltd (PGCIL)
                                    Charges
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.trans_cost
                                            ? formik.errors.trans_cost
                                                .pgcil_chrgs
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.trans_cost.pgcil_chrgs}
                                        onChange={formik.handleChange(
                                          "trans_cost.pgcil_chrgs"
                                        )}
                                      />
                                      {formik.errors && formik.errors.trans_cost
                                        ? formik.errors.trans_cost
                                            .pgcil_chrgs && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>State Load Despatch Centre Charges</td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.trans_cost
                                            ? formik.errors.trans_cost
                                                .state_des_chrgs
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={
                                          values.trans_cost.state_des_chrgs
                                        }
                                        onChange={formik.handleChange(
                                          "trans_cost.state_des_chrgs"
                                        )}
                                      />
                                      {formik.errors && formik.errors.trans_cost
                                        ? formik.errors.trans_cost
                                            .state_des_chrgs && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td style={{ fontWeight: "bold" }}>
                                    TOTAL TRANSMISSION COST
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    {totaltransmission(values)}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    INR in Crs
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Card>
                        </div>
                      </Row>
                    </Collapse>
                    <div
                      onClick={() => setOpen3(!open3)}
                      className=" accordion-container"
                    >
                      <div className="accordion-inside">
                        <h6 className="mb-0 accordion-header-text">
                          4. COST TOWARDS LINE LOSSES
                        </h6>
                        <div>
                          <IconContext.Provider
                            value={{
                              size: "40px",
                              color: "#42BD41",
                            }}
                          >
                            <RiArrowDropDownLine />
                          </IconContext.Provider>
                        </div>
                      </div>
                    </div>
                    <Collapse isOpen={open3}>
                      <Row style={{ marginBottom: "50px" }}>
                        <div className="col">
                          <Card className="shadow">
                            <CardHeader
                              className="border-0"
                              style={{ backgroundColor: "#bdbdbd" }}
                            >
                              <h5 className="mb-0">
                                4.1 COST TOWARDS LINE LOSSES
                              </h5>
                            </CardHeader>
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    Total Purchase Cost including Transmission
                                    Charges
                                  </td>
                                  <td>{lineloss(values)}</td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>
                                    Per unit Purchase Cost (including
                                    Transmission Charges)
                                  </td>
                                  <td>{unitpur_cost_trans}</td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>
                                    Per unit Purchase Cost (including
                                    Transmission Charges) in terms of unit sold
                                  </td>
                                  <td>{unitpur_costsold}</td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td style={{ fontWeight: "bold" }}>
                                    PER UNIT COST OF LINE LOSS
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    {unitcost_lineloss}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    INR
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Card>
                        </div>
                      </Row>
                    </Collapse>
                    <div
                      onClick={() => setOpen4(!open4)}
                      style={{
                        border:
                          (formik.errors && formik.errors.distr_cost1) ||
                          formik.errors.distr_cost2
                            ? "1px solid #dc3545 "
                            : "1px solid rgb(184, 184, 184)",
                      }}
                      className=" accordion-container"
                    >
                      <div className="accordion-inside">
                        <h6 className="mb-0 accordion-header-text">
                          5 DISTRIBUTION COSTS
                        </h6>
                        <div>
                          <IconContext.Provider
                            value={{
                              size: "40px",
                              color: "#42BD41",
                            }}
                          >
                            <RiArrowDropDownLine />
                          </IconContext.Provider>
                        </div>
                      </div>
                    </div>

                    <Collapse isOpen={open4} style={{ marginBottom: "50px" }}>
                      <Row style={{ marginBottom: "50px" }}>
                        <div className="col">
                          <Card className="shadow">
                            <CardHeader
                              className="border-0"
                              style={{ backgroundColor: "#bdbdbd" }}
                            >
                              <h5 className="mb-0">5.1 Expenses</h5>
                            </CardHeader>
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <tbody>
                                <tr>
                                  <td>Operating & Maintenance Expenses</td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.distr_cost1
                                            ? formik.errors.distr_cost1
                                                .opr_main_exp
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.distr_cost1.opr_main_exp}
                                        onChange={formik.handleChange(
                                          "distr_cost1.opr_main_exp"
                                        )}
                                      />
                                      {formik.errors &&
                                      formik.errors.distr_cost1
                                        ? formik.errors.distr_cost1
                                            .opr_main_exp && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>Depreciation</td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.distr_cost1
                                            ? formik.errors.distr_cost1
                                                .deprication
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.distr_cost1.deprication}
                                        onChange={formik.handleChange(
                                          "distr_cost1.deprication"
                                        )}
                                      />
                                      {formik.errors &&
                                      formik.errors.distr_cost1
                                        ? formik.errors.distr_cost1
                                            .deprication && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>Interest and Finance Charges</td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.distr_cost1
                                            ? formik.errors.distr_cost1
                                                .int_fin_chrgs
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.distr_cost1.int_fin_chrgs}
                                        onChange={formik.handleChange(
                                          "distr_cost1.int_fin_chrgs"
                                        )}
                                      />
                                      {formik.errors &&
                                      formik.errors.distr_cost1
                                        ? formik.errors.distr_cost1
                                            .int_fin_chrgs && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>Interest on Working Capital</td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.distr_cost1
                                            ? formik.errors.distr_cost1
                                                .int_capital
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.distr_cost1.int_capital}
                                        onChange={formik.handleChange(
                                          "distr_cost1.int_capital"
                                        )}
                                      />
                                      {formik.errors &&
                                      formik.errors.distr_cost1
                                        ? formik.errors.distr_cost1
                                            .int_capital && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>Other Debits</td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.distr_cost1
                                            ? formik.errors.distr_cost1
                                                .other_depts
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.distr_cost1.other_depts}
                                        onChange={formik.handleChange(
                                          "distr_cost1.other_depts"
                                        )}
                                      />
                                      {formik.errors &&
                                      formik.errors.distr_cost1
                                        ? formik.errors.distr_cost1
                                            .other_depts && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>Other Interest and Finance Charges</td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.distr_cost1
                                            ? formik.errors.distr_cost1
                                                .other_int_fc
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.distr_cost1.other_int_fc}
                                        onChange={formik.handleChange(
                                          "distr_cost1.other_int_fc"
                                        )}
                                      />
                                      {formik.errors &&
                                      formik.errors.distr_cost1
                                        ? formik.errors.distr_cost1
                                            .other_int_fc && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td style={{ fontWeight: "bold" }}>
                                    Gross Revenue Requirement for Distribution
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    {totaldistcost1(values)}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    INR in Crs
                                  </td>
                                </tr>
                                <tr>
                                  <td>Employee cost</td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.distr_cost1
                                            ? formik.errors.distr_cost1
                                                .employee_cost
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.distr_cost1.employee_cost}
                                        onChange={formik.handleChange(
                                          "distr_cost1.employee_cost"
                                        )}
                                      />
                                      {formik.errors &&
                                      formik.errors.distr_cost1
                                        ? formik.errors.distr_cost1
                                            .employee_cost && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                              </tbody>
                            </Table>
                          </Card>
                        </div>
                      </Row>

                      <Row style={{ marginBottom: "50px" }}>
                        <div className="col">
                          <Card className="shadow">
                            <CardHeader
                              className="border-0"
                              style={{ backgroundColor: "#bdbdbd" }}
                            >
                              <h5 className="mb-0">5.2 Income</h5>
                            </CardHeader>
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <tbody>
                                <tr>
                                  <td>Less: Other Income</td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.distr_cost2
                                            ? formik.errors.distr_cost2
                                                .less_income
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={values.distr_cost2.less_income}
                                        onChange={formik.handleChange(
                                          "distr_cost2.less_income"
                                        )}
                                      />
                                      {formik.errors &&
                                      formik.errors.distr_cost2
                                        ? formik.errors.distr_cost2
                                            .less_income && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>Less: Non-tariff Income</td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        min={0}
                                        className={
                                          formik.errors &&
                                          formik.errors.distr_cost2
                                            ? formik.errors.distr_cost2
                                                .less_nontariff_inc
                                              ? "form-control is-invalid"
                                              : "form-control"
                                            : "form-control"
                                        }
                                        value={
                                          values.distr_cost2.less_nontariff_inc
                                        }
                                        onChange={formik.handleChange(
                                          "distr_cost2.less_nontariff_inc"
                                        )}
                                      />
                                      {formik.errors &&
                                      formik.errors.distr_cost2
                                        ? formik.errors.distr_cost2
                                            .less_nontariff_inc && (
                                            <div className="table_error_position">
                                              <p>Required</p>
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td>
                                    Less: Total Tariff and non-Tariff income
                                  </td>
                                  <td>{totaldistcost2(values)}</td>
                                  <td>INR in Crs</td>
                                </tr>
                                <tr>
                                  <td style={{ fontWeight: "bold" }}>
                                    Gross Revenue Requirement for Distribution
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    {netdiscost}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "#393737",
                                      color: "#fff",
                                    }}
                                  >
                                    INR in Crs
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Card>
                        </div>
                      </Row>
                    </Collapse>
                    <div className="d-flex flex-row align-items-center justify-content-end mt-4 mb-4">
                      <Button
                        variant="contained"
                        id="createBtn"
                        className={"createBtn"}
                        onClick={formik.handleSubmit}
                        // disabled={true}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </>
              );
            }}
          </Formik>
        )}
      </div>
    </>
  );
};

export default NewTariff;
